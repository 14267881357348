/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import {
    CometChatTheme,
    CometChatUIKitConstants,
    CometChatMessageEvents,
    CometChatUIEvents,
    CometChatMessageComposerAction,
    localize,
    CometChatActionsView,
} from "@cometchat/uikit-resources";
import { DataSource } from "../../Shared/Framework/DataSource";
import { DataSourceDecorator } from "../../Shared/Framework/DataSourceDecorator";
import { CometChatButton } from "../../Shared/Views/CometChatButton";
import AISmartRepliesView from "./AISmartRepliesView";
import { AIOptionsStyle, AISmartRepliesConfiguration } from "@cometchat/uikit-shared";

export class AISmartRepliesDecorator extends DataSourceDecorator {
    public configuration?: AISmartRepliesConfiguration;
    public newDataSource!: DataSource;
    public loggedInUser!: CometChat.User | null;
    public user!: CometChat.User;
    public group!: CometChat.Group;
    public theme: CometChatTheme = new CometChatTheme({});
    public buttonRef: any;
    public isModalClosed: boolean = true;
    private closeCallback?: () => void;

    constructor(
        dataSource: DataSource,
        configuration?: AISmartRepliesConfiguration
    ) {
        super(dataSource);
        this.newDataSource = dataSource;
        this.configuration = configuration!;
        setTimeout(() => {
            this.addMessageListener();
        }, 1000);
    }

    childRefCallback = (childRef: React.RefObject<typeof CometChatButton>): void => {
        this.buttonRef = childRef
    };

    override getId(): string {
        return "aismartreplies";
    }

    editReply(reply: string) {
        CometChatUIEvents.ccComposeMessage.next(reply);
    }

    closeIfMessageReceived(message: CometChat.BaseMessage) {
        if (message?.getReceiverId() === this.loggedInUser?.getUid()) {
            if (this.closeCallback) {
                if (!this.isModalClosed) {
                    this.closeCallback();
                    this.isModalClosed = true;
                }

            }
        }
    }

    getSmartReplies = (theme?: CometChatTheme): Promise<string[]> => {
        this.theme = theme ?? new CometChatTheme({});
        return new Promise(async (resolve, reject) => {
            try {
                let receiverId: string = this.user
                    ? this.user?.getUid()
                    : this.group?.getGuid();
                let receiverType: string = this.user
                    ? CometChatUIKitConstants.MessageReceiverType.user
                    : CometChatUIKitConstants.MessageReceiverType.group;
                let configuration;

                if (this.configuration?.apiConfiguration) {
                    configuration = await this.configuration?.apiConfiguration(
                        this.user,
                        this.group
                    );
                }
                const response: any = await CometChat.getSmartReplies(
                    receiverId,
                    receiverType,
                    configuration ? configuration : {}
                );

                return resolve(response);
            } catch (e) {
                reject(e);
            }
        });
    };

    override getAIOptions(user: CometChat.User | null, group: CometChat.Group | null, theme: CometChatTheme, id?: any, aiOptionsStyle?: AIOptionsStyle): (CometChatMessageComposerAction | CometChatActionsView)[] {
        this.user = user!;
        this.group = group!;
        if (!id?.parentMessageId) {
            let style;
            if (aiOptionsStyle) {
                style = {
                    titleColor: this.configuration?.smartRepliesStyle?.buttonTextColor || aiOptionsStyle?.listItemTextColor,
                    titleFont: this.configuration?.smartRepliesStyle?.buttonTextFont || aiOptionsStyle?.listItemTextFont,
                    background: this.configuration?.smartRepliesStyle?.buttonBackground || aiOptionsStyle?.listItemBackground
                }
            }
            const messageComposerActions: (CometChatMessageComposerAction | CometChatActionsView)[] = super.getAIOptions(user, group, theme, id, aiOptionsStyle);

            let newAction: CometChatActionsView = new CometChatActionsView({
                title: localize("SUGGEST_A_REPLY"),
                customView: (callback: any) => {

                    this.isModalClosed = false;
                    this.closeCallback = callback?.closePopover;
                    return <AISmartRepliesView title={localize("SUGGEST_A_REPLY")} configuration={this.configuration} getSmartRepliesCallback={this.getSmartReplies} editReplyCallback={this.editReply} closeCallback={callback.closePopover} backCallback={callback.backAction} />
                },
                id: "ai-smart-replies",
                iconURL: '',
                iconTint: '',
                titleColor: style?.titleColor,
                titleFont: style?.titleFont,
                background: style?.background,
            });
            messageComposerActions.push(newAction);
            return messageComposerActions;
        } else {
            return super.getAIOptions(user, group, theme, id, aiOptionsStyle);
        }
    }

    private addMessageListener(): void {
        CometChat.getLoggedinUser().then((user: CometChat.User | null) => {
            if (user) {
                this.loggedInUser = user;
            }
        });

        CometChatMessageEvents.onTextMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onCustomMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onMediaMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onFormMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onSchedulerMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onCardMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });

        CometChatMessageEvents.onCustomInteractiveMessageReceived.subscribe((message) => {
            this.closeIfMessageReceived(message);
        });
    }
}
