import { AISmartRepliesConfiguration, CometChatAICard, SmartReplies } from '@cometchat/uikit-shared';
import { CometChatTheme, States, localize } from '@cometchat/uikit-resources';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { contentContainerStyle, getBackButtonStyle, getContainerStyle, getSmartRepliesContainerStyle, getSmartRepliesTitleStyle, getSmartReplyStyle } from './style';

import { CometChatButton } from '../../Shared/Views/CometChatButton';
import { CometChatThemeContext } from '../../CometChatThemeContext';
import backIcon from '../assets/backbutton.svg';
import { createComponent } from "@lit-labs/react";
import emptyIcon from '../assets/ai-empty.svg';
import errorIcon from '../assets/ai-error.svg';
import loadingIconURL from '../assets/loading.svg';

interface IAISmartRepliesProps {
    title: string
    getSmartRepliesCallback?: (theme?: CometChatTheme) => Promise<Object>
    editReplyCallback?: (reply: string) => void
    closeCallback?: () => void
    backCallback?: () => void
    configuration?: AISmartRepliesConfiguration
};

const defaultProps: IAISmartRepliesProps = {
    title: localize("SUGGEST_A_REPLY"),
    getSmartRepliesCallback: undefined,
    editReplyCallback: undefined,
    closeCallback: undefined,
    backCallback: undefined,
    configuration: undefined
}

const CometChatSmartRepliesView = createComponent({
    tagName: 'smart-replies',
    elementClass: SmartReplies,
    react: React,
    events: {
        'ccReplyClicked': 'cc-reply-clicked',
        'ccCloseClicked': 'cc-close-clicked'
    }
});

const CometChatAICardView = createComponent({
    tagName: 'cometchat-ai-card',
    elementClass: CometChatAICard,
    react: React
});

const AISmartRepliesView = (props: IAISmartRepliesProps) => {
    const {
        title,
        getSmartRepliesCallback,
        editReplyCallback,
        closeCallback,
        backCallback,
        configuration,
    } = { ...defaultProps, ...props };
    const [messageListState, setMessageListState] = useState<States>(States.loading);
    const [activeView, setActiveView] = useState<JSX.Element | null>(null);

    const { theme } = useContext(CometChatThemeContext)

    const errorStateText: string = localize("SOMETHING_WRONG");
    const emptyStateText: string = localize("NO_MESSAGES_FOUND");
    const loadingStateText: string = localize("GENERATING_REPLIES");

    useEffect(() => {
        fetchButtonContent();
    }, []);

    function fetchButtonContent() {
        setMessageListState(States.loading);
        if (props && getSmartRepliesCallback) {
            getSmartRepliesCallback(theme).then(async (response) => {
                if (response) {
                    setMessageListState(States.loaded);
                    setActiveView(await getLoadedView(response));
                } else {
                    setMessageListState(States.empty);
                }
            })
                .catch((err) => {
                    setMessageListState(States.error);
                })
        }
    }

    /**
     * Create a view based on the value of the `state` prop.
     */
    function getStateView(): JSX.Element | null {
        let res: JSX.Element | null = null;
        switch (messageListState) {
            case States.loading:
                res = getLoadingView();
                break;
            case States.error:
                res = getErrorView();
                break;
            case States.empty:
                res = getEmptyView();
                break;
            case States.loaded:
                break;
            default:
                const x: never = messageListState;
        }
        return res;
    }

    /**
     * Creates the loading view
     */
    function getLoadingView(): JSX.Element {
        let LoadingView = configuration?.loadingStateView;
        return (
            <CometChatAICardView
                state={States.loading}
                style={configuration?.smartRepliesStyle}
                loadingIconURL={configuration?.loadingIconURL || loadingIconURL}
                loadingStateText={loadingStateText}
            >
                {LoadingView ? <div slot='loadingView'>{<LoadingView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the error view
     */
    function getErrorView(): JSX.Element | null {
        let ErrorView = configuration?.errorStateView;
        return (
            <CometChatAICardView
                state={States.error}
                style={configuration?.smartRepliesStyle}
                errorIconURL={configuration?.errorIconURL || errorIcon}
                errorStateText={errorStateText}
            >
                {ErrorView ? <div slot='errorView'>{<ErrorView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the empty view
     */
    function getEmptyView(): JSX.Element {
        let EmptyView = configuration?.emptyStateView;
        return (
            <CometChatAICardView
                state={States.empty}
                style={configuration?.smartRepliesStyle}
                emptyIconURL={configuration?.emptyIconURL || emptyIcon}
                emptyStateText={emptyStateText}
            >
                {EmptyView ? <div slot='errorView'>{<EmptyView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the loaded view
     */
    async function getLoadedView(smartReplies: any): Promise<JSX.Element> {
        return new Promise((resolve, reject) => {
            try {
                let CustomView = configuration?.customView;

                if (CustomView) {
                    configuration?.customView!(smartReplies, closeCallback, backCallback).then((res: any) => {
                        return resolve(res);
                    })
                        .catch((err: CometChat.CometChatException) => {
                            return reject(err)
                        })
                } else {
                    let repliesArray: string[] = [];
                    Object.keys(smartReplies).forEach((reply) => {
                        if (smartReplies[reply] && smartReplies[reply] !== "") {
                            repliesArray.push(smartReplies[reply]);
                        }
                    });
                    let SmartRepliesView = (
                        <div style={getSmartRepliesContainerStyle()}>
                            <div
                                style={getSmartRepliesTitleStyle(
                                    configuration?.smartRepliesStyle,
                                    theme
                                )}
                            >
                                <div>
                                    <CometChatButton
                                        iconURL={backIcon}
                                        onClick={() =>
                                            backCallback ? backCallback() : null
                                        }
                                        buttonStyle={getBackButtonStyle(
                                            configuration?.smartRepliesStyle,
                                            theme
                                        )}
                                    />
                                </div>
                                <div>{title}</div>
                                <div style={{ flex: 0.2 }}></div>
                            </div>
                            <CometChatSmartRepliesView
                                smartReplyStyle={getSmartReplyStyle(
                                    theme,
                                    configuration?.smartRepliesStyle
                                )}
                                replies={repliesArray}
                                ccReplyClicked={(e: any) => {
                                    props && editReplyCallback
                                        ? editReplyCallback(e?.detail?.reply)
                                        : null;
                                    props && closeCallback
                                        ? closeCallback()
                                        : null;
                                }}
                                key={"smart-replies"}
                                closeIconURL=""
                            />
                        </div>
                    );
                    return resolve(SmartRepliesView);
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    return (
        <div
            className="slideable-container"
            style={getContainerStyle(configuration?.smartRepliesStyle, theme)}
        >
            <div style={contentContainerStyle}>
                {messageListState === States.loaded ? (
                    <div style={{ height: "100%" }}>
                        {activeView}
                    </div>
                ) : null}
                {messageListState !== States.loaded ? getStateView() : null}
            </div>
        </div>
    );
};

export default AISmartRepliesView;
