import { AvatarStyle, BackdropStyle, ListItemStyle } from "@cometchat/uikit-elements";
import {
  CometChatMessageEvents,
  CometChatMessageTemplate,
  CometChatTheme,
  CometChatUIKitConstants,
  DatePatterns,
  MessageBubbleAlignment,
  Receipts,
  States,
  fontHelper,
  localize,
} from "@cometchat/uikit-resources";
import { CometChatUIKitLoginListener, MessageInformationStyle } from "@cometchat/uikit-shared";
import { MessageDateStyle, closeBtnStyle, dateInfoStyle, defaultDateSeparatorStyle, dividerStyle, getBackdropStyle, getMessageInfoStyle, getMessageTextStyle, getParentBubbleStyle, getReceiptTextStyle, messageInfoHeaderStyle, messageInfoStyle, receiptStyle, receiptSubtitleWrapperStyle, receiptWrapperStyle } from "./style"
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../Shared/Views/CometChatButton";
import { CometChatList } from "../Shared/Views/CometChatList";
import { CometChatListItem } from "../Shared/Views/CometChatListItem";
import { CometChatThemeContext } from "../CometChatThemeContext"
import { CometChatUIKit } from "../Shared/CometChatUIKit/CometChatUIKit";
import DeliveredIcon from "./assets/message-delivered.svg";
import { MessageUtils } from "../Shared/Utils/MessageUtils";
import ReadIcon from "./assets/message-read.svg";
import SpinnerIcon from "./assets/spinner.svg";
import WarningIcon from "./assets/warning-small.svg";

interface MessageInformationProps {
  title?: string;
  message: CometChat.BaseMessage;
  template?: CometChatMessageTemplate;
  closeIconURL?: string;
  bubbleView?: (messageObject: CometChat.BaseMessage) => void | JSX.Element;
  listItemView?: (
    messageObject: CometChat.BaseMessage,
    messageReceipt?: CometChat.MessageReceipt
  ) => JSX.Element;
  subtitleView?: (
    messageObject: CometChat.BaseMessage,
    messageReceipt?: CometChat.MessageReceipt
  ) => void | JSX.Element;
  receiptDatePattern?: (timestamp: number) => string;
  onClose?: () => void;
  onError?: ((error: CometChat.CometChatException) => void) | null;
  messageInformationStyle?: MessageInformationStyle;
  readIcon?: string;
  deliveredIcon?: string;
  listItemStyle?: ListItemStyle;
  emptyStateText?: any;
  emptyStateView?: any;
  loadingIconURL?: string;
  loadingStateView?: any;
  errorStateText?: any;
  errorStateView?: any;
  backdropStyle?: BackdropStyle;
}

const CometChatMessageInformation = (props: MessageInformationProps) => {
  const { theme } = useContext(CometChatThemeContext);

  const {
    title = localize("MESSAGE_INFORMATION"),
    closeIconURL,
    onClose,
    message,
    bubbleView,
    subtitleView = null,
    listItemView = null,
    messageInformationStyle,
    listItemStyle,
    receiptDatePattern,
    template,
    loadingIconURL = SpinnerIcon,
    backdropStyle = new BackdropStyle({})
  } = props;

  const [userObj, setUserObj] = useState<CometChat.User>();
  const [templateRef, setTemplateRef] = useState<CometChatMessageTemplate>(template!);
  const [messageReceipts, setMessageReceipts] = useState<
    CometChat.MessageReceipt[]
  >([]);
  const loggedInUser = useRef<CometChat.User | null>(null);

  const getListItemStyle = () => {
    let defaultStyle: ListItemStyle = new ListItemStyle({
      height: "70px",
      width: "100%",
      background: "inherit",
      borderRadius: "0",
      titleFont: fontHelper(theme.typography.title2),
      titleColor: theme.palette.getAccent(),
      border: "none",
      separatorColor: theme.palette.getAccent200(),
      hoverBackground: theme.palette.getAccent50(),
    });
    return { ...defaultStyle, ...listItemStyle };
  };

  async function getMessageReceipt(message?: CometChat.BaseMessage) {
    if (
      message?.getReceiverType() ===
      CometChatUIKitConstants.MessageReceiverType.group
    ) {
      let messageReceiptVal: CometChat.MessageReceipt | any =
        await CometChat.getMessageReceipts(message?.getId());
      let receiptList = messageReceiptVal.filter((receipt: CometChat.MessageReceipt) =>
        receipt.getSender().getUid() !== loggedInUser.current?.getUid()) as CometChat.MessageReceipt[]
      setMessageReceipts(receiptList);
      return messageReceiptVal;
    }
  }

  useEffect(() => {
    if (!template) {
      const getTemplateRef = CometChatUIKit.getDataSource()?.getMessageTemplate(props.message?.getType(), props.message?.getCategory(), theme);
      if (getTemplateRef)
        setTemplateRef(getTemplateRef);
    }
  }, [props.message, template, theme])

  const subscribeToUIEvents: () => (() => void) | undefined = useCallback(() => {
    try {
      const onMessagesDeliveredToAll = CometChatMessageEvents.onMessagesDeliveredToAll.subscribe((messageReceipt: CometChat.MessageReceipt) => {
        messageReadAndDelivered(messageReceipt);
      });
      const onMessagesReadByAll = CometChatMessageEvents.onMessagesReadByAll.subscribe((messageReceipt: CometChat.MessageReceipt) => {
        messageReadAndDelivered(messageReceipt);
      });

      return () => {
        try {
          onMessagesDeliveredToAll?.unsubscribe();
          onMessagesReadByAll?.unsubscribe();
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [])

  const messageReadAndDelivered: (message: CometChat.MessageReceipt) => void = useCallback(
    (messageReceipt: CometChat.MessageReceipt) => {
      try {
        if (
          message?.getReceiverType() ===
          CometChatUIKitConstants.MessageReceiverType.group
        ) {
          getMessageReceipt(message);
        }
      } catch (error) {
        console.log(error)
      }
    }, [])

  useEffect(() => {
    const unsubscribeEvents = subscribeToUIEvents();

    return () => {
      unsubscribeEvents?.();
    }
  }, [])

  useEffect(() => {
    if (
      message?.getReceiverType() ===
      CometChatUIKitConstants.MessageReceiverType.user
    ) {
      setUserObj(message.getReceiver() as CometChat.User);
    }
    if (
      message?.getReceiverType() ===
      CometChatUIKitConstants.MessageReceiverType.group
    ) {
      getMessageReceipt(message);
    }
  }, [message]);

  function getCloseBtnView() {
    return (
      <CometChatButton
        iconURL={closeIconURL}
        hoverText={localize("CLOSE")}
        buttonStyle={closeBtnStyle(theme)}
        onClick={onClose}
      />
    );
  }

  const getBubbleView = useCallback(() => {
    if (CometChatUIKitLoginListener.getLoggedInUser()) {
      loggedInUser.current = CometChatUIKitLoginListener.getLoggedInUser();
    }
    if (message) {
      if (bubbleView) return bubbleView(message);
      else {

        if (!templateRef) {
          return <></>
        }

        const style = new MessageUtils().messageBubbleStyle(
          message,
          theme,
          MessageBubbleAlignment.right,
          loggedInUser.current
        );
        const view = new MessageUtils().getMessageBubble(
          message,
          templateRef,
          style,
          MessageBubbleAlignment.right
        );
        return view;
      }
    }
    return null;
  }, [message, bubbleView, templateRef, theme]);

  function getListItemSubtitleView(
    deliveredAt: number,
    readAt?: number | undefined
  ): JSX.Element {
    if (subtitleView !== null && typeof (subtitleView) === "function") {
      return <>{subtitleView(message)}</>;
    }

    return (
      <div style={receiptSubtitleWrapperStyle}>
        <div className="cc-receipts__subtitle">
          {getSubtitleReadReceiptView(deliveredAt, readAt)}
        </div>
      </div>
    );
  }

  /**
   * Creates subtitle receipt view
   */
  function getSubtitleReadReceiptView(
    deliveredAt: number,
    readAt?: number
  ): JSX.Element | null {
    return (
      <div className="cc-message_information__receipt-container">
        <div
          className="cc-message_information__receipt"
          style={receiptWrapperStyle}
        >
          <div className="cc-message_information__delivered-receipt">
            <cometchat-receipt
              receipt={Receipts.delivered}
              errorIcon={WarningIcon}
              deliveredIcon={DeliveredIcon}
              receiptStyle={JSON.stringify(receiptStyle(theme))}
            />
          </div>
          <div
            style={subtitleStyle}
            className="cc_message-information__subtitle-text"
          >
            {deliveredAt !== undefined && localize("DELIVERED")}
          </div>
          <div className="cc_message-information__date" style={dateInfoStyle}>
            <cometchat-date
              timestamp={deliveredAt}
              customDateString={
                receiptDatePattern ? receiptDatePattern(deliveredAt) : null
              }
              pattern={receiptDatePattern ? null : DatePatterns.DateTime}
              dateStyle={JSON.stringify(
                MessageDateStyle(defaultDateSeparatorStyle, theme)
              )}
            />
          </div>
        </div>
        {readAt && <div
          className="cc-message_information__receipt"
          style={receiptWrapperStyle}
        >
          <div className="cc-message_information__read-receipt">
            <cometchat-receipt
              receipt={Receipts.read}
              errorIcon={WarningIcon}
              readIcon={ReadIcon}
              receiptStyle={JSON.stringify(receiptStyle(theme))}
            />
          </div>
          <div
            style={subtitleStyle}
            className="cc_message-information__subtitle-text"
          >
            {readAt !== undefined && localize("READ")}
          </div>
          {readAt !== undefined && (
            <div className="cc_message-information__date" style={dateInfoStyle}>
              <cometchat-date
                timestamp={readAt}
                customDateString={
                  receiptDatePattern ? receiptDatePattern(readAt) : null
                }
                pattern={receiptDatePattern ? null : DatePatterns.DateTime}
                dateStyle={JSON.stringify(
                  MessageDateStyle(defaultDateSeparatorStyle, theme)
                )}
              />
            </div>
          )}
          {readAt === undefined && (
            <div className="cc_message-information__date" style={dateInfoStyle}>
              ...
            </div>
          )}
        </div>
        }
      </div>
    );
  }

  const subtitleStyle = {
    font: fontHelper(theme.typography.subtitle2),
    color: theme.palette.getAccent600(),
  };

  const defaultAvatarStyle = new AvatarStyle({
    borderRadius: "50%",
    width: "43px",
    height: "43px",
  });

  function getListitemReceipt(
    messageReceipt: CometChat.MessageReceipt
  ): JSX.Element {
    return (
      <>
        {listItemView
          ? listItemView(message, messageReceipt)
          : getDefaultListItemView(messageReceipt)}
      </>
    );
  }

  /**
   * Creates default list item view
   */
  function getDefaultListItemView(messageReceipt: CometChat.MessageReceipt) {
    return (
      <CometChatListItem
        id={messageReceipt.getMessageId()}
        title={messageReceipt.getSender()?.getName()}
        avatarURL={messageReceipt.getSender()?.getAvatar()}
        avatarName={messageReceipt.getSender()?.getName()}
        hideSeparator={false}
        isActive={false}
        avatarStyle={defaultAvatarStyle}
        listItemStyle={getListItemStyle()}
        subtitleView={getListItemSubtitleView(
          messageReceipt.getDeliveredAt(),
          messageReceipt.getReadAt()
        )}
        subtitleViewClassName="cc-message_information__subtitle-view"
        tailViewClassName="cc-receipt-details__tail-view"
      />
    );
  }

  return (
    <div
      className="cc__messageinfo__wrapper"
      style={receiptSubtitleWrapperStyle}
    >
      <cometchat-backdrop
        backdropStyle={JSON.stringify(getBackdropStyle(backdropStyle))}
      >
        <div
          className="cc__messageinfo"
          style={getMessageInfoStyle(theme, messageInformationStyle)}
        >
          <div
            className="cc__messageinfo__header"
            style={{
              textAlign: "center",
              fontSize: "22px",
              fontWeight: "700",
              padding: "19px 0px",
              position: "relative",
            }}
          >
            <div className="cc__messageinfo__title"> {title} </div>
            {getCloseBtnView()}
          </div>
          <div
            className="cc__messageinfo__content"
            style={receiptSubtitleWrapperStyle}
          >
            <div
              style={getMessageTextStyle(theme)}
            >
              {localize("MESSAGE")}
            </div>
            <cometchat-divider
              dividerStyle={JSON.stringify(dividerStyle(theme))}
            ></cometchat-divider>
            <div className="cc__messageinfo__bubble-wrapper" style={getParentBubbleStyle(bubbleView ? false : true
            )}>{getBubbleView()}</div>
            <cometchat-divider
              dividerStyle={JSON.stringify(dividerStyle(theme))}
            ></cometchat-divider>
            <div
              style={getReceiptTextStyle(theme)}
            >
              {localize("RECEIPT_INFORMATION")}
            </div>
            <cometchat-divider
              dividerStyle={JSON.stringify(dividerStyle(theme))}
            ></cometchat-divider>
            {message.getReceiverType() ===
              CometChatUIKitConstants.MessageReceiverType.user &&
              message.getDeliveredAt() !== undefined && (
                <div>
                  <CometChatListItem
                    id={message.getConversationId()}
                    title={userObj?.getName()}
                    avatarURL={userObj?.getAvatar()}
                    avatarName={userObj?.getName()}
                    hideSeparator={false}
                    isActive={false}
                    avatarStyle={defaultAvatarStyle}
                    listItemStyle={getListItemStyle()}
                    subtitleView={getListItemSubtitleView(
                      message.getDeliveredAt(),
                      message.getReadAt()
                    )}
                    subtitleViewClassName="cc-message_information__subtitle-view"
                    tailViewClassName="cc-receipt-details__tail-view"
                  />
                </div>
              )}
            {message.getReceiverType() ===
              CometChatUIKitConstants.MessageReceiverType.user &&
              message.getDeliveredAt() === undefined && (
                <div
                  style={{
                    padding: "2em 0px 0px 0px",
                    fontSize: "18px",
                    textAlign: "center",
                    color: theme.palette.getAccent400(),
                  }}
                >
                  {" "}
                  {localize("NO_RECIPIENT")}{" "}
                </div>
              )}
            {message.getReceiverType() ===
              CometChatUIKitConstants.MessageReceiverType.group &&
              messageReceipts.length > 0 && (
                <div>
                  <CometChatList
                    list={messageReceipts}
                    listItem={getListitemReceipt}
                    state={
                      messageReceipts.length === 0
                        ? States.loading
                        : States.loaded
                    }
                    hideSearch={true}
                    showSectionHeader={false}
                    loadingIconURL={loadingIconURL}
                  />
                </div>
              )}
            {message.getReceiverType() ===
              CometChatUIKitConstants.MessageReceiverType.group &&
              messageReceipts.length <= 0 && (
                <div
                  style={{
                    padding: "2em 0px 0px 0px",
                    fontSize: "18px",
                    textAlign: "center",
                    color: theme.palette.getAccent400(),
                  }}
                >
                  {" "}
                  {localize("NO_RECIPIENTS")}{" "}
                </div>
              )}
          </div>
        </div>
      </cometchat-backdrop>
    </div>
  );
};

export { CometChatMessageInformation };