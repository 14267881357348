import {
  BaseStyle,
  ContactsStyle,
  GroupsConfiguration,
  TabItemStyle,
  UsersConfiguration,
} from "@cometchat/uikit-shared";
import {
  CometChatTabItem,
  CometChatTheme,
  SelectionMode,
  TabAlignment,
  TabsVisibility,
  fontHelper,
  localize,
} from "@cometchat/uikit-resources";
import {
  closeBtnStyle,
  contactsContentStyle,
  contactsHeaderStyle,
  getContactsStyle,
  contactsWrapperStyle,
  getTabsStyle,
  getTabItemStyling,
  submitBtnStyle,
  submitBtnWrapperStyle,
} from "./style";

import CloseIcon from "./assets/close2x.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../Shared/Views/CometChatButton";
import { CometChatGroups } from "../CometChatGroups";
import { CometChatTabs } from "../Shared/Views/CometChatTabs/index";
import { CometChatThemeContext } from "../CometChatThemeContext";
import { CometChatUsers } from "../CometChatUsers";
import { useContext, useRef } from "react";
import React from "react";

interface ContactsProps {
  title?: string;
  usersTabTitle?: string;
  groupsTabTitle?: string;
  usersConfiguration?: UsersConfiguration;
  groupsConfiguration?: GroupsConfiguration;
  onSubmitButtonClick?: (users?: CometChat.User[], groups?: CometChat.Group[]) => void,
  closeIconURL?: string;
  onClose?: () => void;
  onItemClick?: (user?: CometChat.User, group?: CometChat.Group) => void;
  onError?: ((error: CometChat.CometChatException) => void) | null;
  submitButtonText?: string,
  hideSubmitButton?: boolean,
  selectionLimit?: number,
  tabVisibility?: TabsVisibility;
  contactsStyle?: ContactsStyle;
  selectionMode?: SelectionMode;
}

const defaultProps: ContactsProps = {
  title: localize("NEW_CHAT"),
  usersTabTitle: localize("USERS"),
  groupsTabTitle: localize("GROUPS"),
  groupsConfiguration: new GroupsConfiguration({}),
  usersConfiguration: new UsersConfiguration({}),
  onError: (error: CometChat.CometChatException) => {
    console.log(error);
  },
  tabVisibility: TabsVisibility.usersAndGroups,
  contactsStyle: {},
  selectionLimit: 5,
  hideSubmitButton: true,
  submitButtonText: "Submit",
  selectionMode: SelectionMode.none
};

const CometChatContacts = (props: ContactsProps) => {
  const [tabs, setTabs] = React.useState<CometChatTabItem[]>([]);
  const groupsListRef = useRef<CometChat.Group[]>([]);
  const usersListRef = useRef<CometChat.User[]>([]);
  const [isActiveUsersTab, setIsActiveUsersTab] = React.useState<boolean>(true);
  const [isLimitReached, setIsLimitReached] = React.useState<boolean>(true);
  const [isActiveGroupsTab, setIsActiveGroupsTab] = React.useState<boolean>(false);
  const usersTabRef = React.useRef<CometChatTabItem>();
  const groupsTabRef = React.useRef<CometChatTabItem>();
  const { theme } = useContext(CometChatThemeContext);
  const {
    title,
    usersTabTitle,
    groupsTabTitle,
    closeIconURL,
    onClose,
    usersConfiguration,
    groupsConfiguration,
    onItemClick,
    tabVisibility,
    contactsStyle,
    selectionLimit,
    hideSubmitButton,
    submitButtonText,
    selectionMode,
    onSubmitButtonClick
  } = { ...defaultProps, ...props };

  React.useEffect(() => {

    const usersRequestBuilder = new CometChat.UsersRequestBuilder()
      .setLimit(30)
      .hideBlockedUsers(true);
    const usersSearchRequestBuilder = new CometChat.UsersRequestBuilder()
      .setLimit(30)
      .hideBlockedUsers(true);

    const groupsRequestBuilder = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true);
    const groupsSearchRequestBuilder = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true);

    usersTabRef.current = new CometChatTabItem({
      id: "users",
      title: usersTabTitle,
      style: getTabItemStyling(theme, tabVisibility, isActiveUsersTab, contactsStyle),
      childView: (
        // <div style={{marginTop: "50px"}}>
        <CometChatUsers
          title=""
          activeUser={undefined}
          hideSearch={usersConfiguration?.hideSearch || false}
          searchIconURL={usersConfiguration?.searchIconURL}
          searchRequestBuilder={
            usersConfiguration?.searchRequestBuilder || usersSearchRequestBuilder
          }
          onItemClick={usersConfiguration?.onItemClick || onUserClicked}
          usersStyle={usersConfiguration?.usersStyle}
          subtitleView={usersConfiguration?.subtitleView}
          options={usersConfiguration?.options ?? undefined}
          usersRequestBuilder={
            usersConfiguration?.usersRequestBuilder || usersRequestBuilder
          }
          emptyStateView={usersConfiguration?.emptyStateView}
          onSelect={usersConfiguration?.onSelect || onUserSelected}
          loadingIconURL={usersConfiguration?.loadingIconURL}
          errorStateView={usersConfiguration?.errorStateView}
          loadingStateView={usersConfiguration?.loadingStateView}
          tileAlignment={usersConfiguration?.titleAlignment}
          showSectionHeader={usersConfiguration?.showSectionHeader}
          listItemView={usersConfiguration?.listItemView}
          menus={usersConfiguration?.menu}
          hideSeparator={usersConfiguration?.hideSeparator}
          hideError={usersConfiguration?.hideError}
          selectionMode={selectionMode}
          listItemStyle={usersConfiguration?.listItemStyle}
          disableUsersPresence={usersConfiguration?.disableUsersPresence}
          statusIndicatorStyle={usersConfiguration?.statusIndicatorStyle}
          avatarStyle={usersConfiguration?.avatarStyle}
        />
        // </div>
      ),
      isActive: isActiveUsersTab,
    });

    groupsTabRef.current = new CometChatTabItem({
      id: "groups",
      title: groupsTabTitle,
      style: getTabItemStyling( theme, tabVisibility, isActiveGroupsTab, contactsStyle),
      childView: (
        <CometChatGroups
          title=""
          activeGroup={undefined}
          hideSearch={groupsConfiguration?.hideSearch || false}
          searchIconURL={groupsConfiguration?.searchIconURL}
          searchRequestBuilder={
            groupsConfiguration?.searchRequestBuilder ||
            groupsSearchRequestBuilder
          }
          onItemClick={groupsConfiguration?.onItemClick || onGroupClicked}
          groupsStyle={groupsConfiguration?.groupsStyle}
          subtitleView={groupsConfiguration?.subtitleView}
          options={groupsConfiguration?.options ?? undefined}
          privateGroupIcon={groupsConfiguration?.privateGroupIcon}
          passwordGroupIcon={groupsConfiguration?.passwordGroupIcon || groupsConfiguration?.protectedGroupIcon}
          groupsRequestBuilder={
            groupsConfiguration?.groupsRequestBuilder || groupsRequestBuilder
          }
          emptyStateView={groupsConfiguration?.emptyStateView}
          onSelect={groupsConfiguration?.onSelect || onGroupSelected}
          loadingIconURL={groupsConfiguration?.loadingIconURL}
          errorStateView={groupsConfiguration?.errorStateView}
          loadingStateView={groupsConfiguration?.loadingStateView}
          titleAlignment={groupsConfiguration?.titleAlignment}
          listItemView={groupsConfiguration?.listItemView}
          menus={groupsConfiguration?.menu}
          hideSeparator={groupsConfiguration?.hideSeparator}
          hideError={groupsConfiguration?.hideError}
          selectionMode={selectionMode}
          listItemStyle={groupsConfiguration?.listItemStyle}
        />
      ),
      isActive: isActiveGroupsTab,
    });

    setTabs([usersTabRef.current, groupsTabRef.current]);

  }, []);

  React.useEffect(() => {

    if (tabVisibility === TabsVisibility.groups) {
      setIsActiveGroupsTab(true);
      setIsActiveUsersTab(false);
      setTabs([groupsTabRef.current!]);
    } else if (tabVisibility === TabsVisibility.users) {
      setIsActiveGroupsTab(false);
      setIsActiveUsersTab(true);
      setTabs([usersTabRef.current!]);
    } else if (tabVisibility === TabsVisibility.usersAndGroups) {
      setIsActiveGroupsTab(false);
      setIsActiveUsersTab(true);
      setTabs([usersTabRef.current!, groupsTabRef.current!]);
    }

  }, [tabVisibility])

  const onUserClicked = (user: CometChat.User) => {
    if (onItemClick) {
      onItemClick(user, undefined);
    }
  };

  const onGroupClicked = (group: CometChat.Group) => {
    if (onItemClick) {
      onItemClick(undefined, group);
    }
  };

  const submitClicked = () => {
    if (onSubmitButtonClick) {
      onSubmitButtonClick(usersListRef.current, groupsListRef.current);
    }
  };


  const onGroupSelected = (group: CometChat.Group) => {
    var key = groupsListRef.current.findIndex((m: any) => m?.getGuid() === group.getGuid());
    if (key >= 0) {
      groupsListRef.current.splice(key, 1);
    } else {
      groupsListRef.current.push(group);
    }
    setIsLimitReached((usersListRef.current.length + groupsListRef.current.length) > selectionLimit!)

  };
  const onUserSelected = (user: CometChat.User) => {
    var key = usersListRef.current.findIndex((m: any) => m?.getUid() === user.getUid());
    if (key >= 0) {
      usersListRef.current.splice(key, 1);
    } else {
      usersListRef.current.push(user);
    }

    setIsLimitReached((usersListRef.current.length + groupsListRef.current.length) > selectionLimit!)
  };


  function getCloseBtnView() {
    const iconURL = closeIconURL && closeIconURL !== "" ? closeIconURL : CloseIcon;
    return (
      <CometChatButton
        iconURL={iconURL}
        hoverText={localize("CLOSE")}
        buttonStyle={closeBtnStyle(theme, contactsStyle)}
        onClick={onClose}
      />
    );
  }
  function submitBtnView() {
    if ((selectionMode === SelectionMode.none && hideSubmitButton)) return <></>;
    return (
      <div className="cc-contacts__submit--buttons" style={submitBtnWrapperStyle()}>
        <CometChatButton
          text={submitButtonText}
          buttonStyle={submitBtnStyle(theme, contactsStyle)}
          disabled={isLimitReached}
          onClick={submitClicked}
        />
      </div>
    );
  }

  return tabs.length ? (
    <div className="cc-contacts-wrapper" style={contactsWrapperStyle(theme, contactsStyle)}>
      <div className="cc-contacts" style={getContactsStyle(theme, contactsStyle)}>
        <div className="cc-contacts-header" style={contactsHeaderStyle(theme, contactsStyle)}>{title}</div>
        <div className="cc-contacts-content" style={contactsContentStyle()}>
          <CometChatTabs
            tabAlignment={TabAlignment.right}
            tabs={tabs}
            tabsStyle={getTabsStyle(theme, contactsStyle)}
            // isDraggable={false}
            keepAlive={true}
          />
          {getCloseBtnView()}
        </div>
        {submitBtnView()}
      </div>
    </div>
  ) : <></>;
};

export { CometChatContacts };
