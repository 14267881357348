import { AvatarStyle, CallscreenStyle, ListItemStyle } from "@cometchat/uikit-elements";
import { CallLogsStyle, CometChatUIKitCalls, OutgoingCallConfiguration, OutgoingCallStyle } from "@cometchat/uikit-shared";
import { CallWorkflow, CometChatCallEvents, CometChatMessageEvents, CometChatUIKitConstants, DatePatterns, MessageStatus, States, TitleAlignment, fontHelper, localize } from "@cometchat/uikit-resources";
import { getAvatarStyle, getCallDateStyle, getContainerStyle, getDateSeparator, getDirectionIconStyle, getInfoButtonStyle, getListItemStyle, getListStyle, getSubtitleStyle } from "./style";
import { getCallStatusWithType, isMissedCall, isSentByMe, verifyCallUser } from "../Utils/utils";
import { useCallback, useContext, useMemo, useRef, useState } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../../Shared/Views/CometChatButton";
import { CometChatList } from "../../Shared/Views/CometChatList";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import { CometChatOngoingCall } from "../CometChatOngoingCall";
import { CometChatOutgoingCall } from "../CometChatOutgoingCall";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { Hooks } from "./hooks";
import IncomingAudioCallIcon from "./assets/IncomingAudio.svg";
import IncomingVideoCallIcon from "./assets/IncomingVideo.svg";
import InfoIcon from "./assets/Info.svg"
import LoadingIcon from "./assets/LoadingIcon.svg";
import MissedAudioCallIcon from "./assets/MissedAudio.svg";
import MissedVideoCallIcon from "./assets/MissedVideo.svg";
import OutgoingAudioCallIcon from "./assets/OutgoingAudio.svg";
import OutgoingVideoCallIcon from "./assets/OutgoingVideo.svg";

interface ICallLogsProps {
    title?: string;
    titleAlignment?: TitleAlignment;
    listItemView?: any;
    subtitleView?: any;
    tailView?: any;
    emptyStateView?: any;
    errorStateView?: any;
    loadingStateView?: any;
    emptyStateText?: string;
    errorStateText?: string;
    loadingIconURL?: string;
    incomingAudioCallIconUrl?: string;
    incomingVideoCallIconUrl?: string;
    outgoingAudioCallIconUrl?: string;
    outgoingVideoCallIconUrl?: string;
    missedAudioCallIconUrl?: string;
    missedVideoCallIconUrl?: string;
    infoIconUrl?: string;
    activeCall?: any;
    callLogRequestBuilder?: any;
    onItemClick?: Function;
    onInfoClick?: Function;
    onError?: Function;
    hideSeparator?: boolean;
    datePattern?: DatePatterns;
    dateSeparatorPattern?: DatePatterns;
    callLogsStyle?: CallLogsStyle;
    avatarStyle?: AvatarStyle;
    listItemStyle?: ListItemStyle;
    outgoingCallConfiguration?: OutgoingCallConfiguration;
}

const defaultProps: ICallLogsProps = {
    title: localize("CALLS"),
    titleAlignment: TitleAlignment.left,
    listItemView: null,
    subtitleView: null,
    tailView: null,
    emptyStateView: null,
    errorStateView: null,
    loadingStateView: null,
    emptyStateText: localize("NO_CALLS_FOUND"),
    errorStateText: localize("SOMETHING_WRONG"),
    loadingIconURL: LoadingIcon,
    incomingAudioCallIconUrl: IncomingAudioCallIcon,
    incomingVideoCallIconUrl: IncomingVideoCallIcon,
    outgoingAudioCallIconUrl: OutgoingAudioCallIcon,
    outgoingVideoCallIconUrl: OutgoingVideoCallIcon,
    missedAudioCallIconUrl: MissedAudioCallIcon,
    missedVideoCallIconUrl: MissedVideoCallIcon,
    infoIconUrl: InfoIcon,
    callLogRequestBuilder: undefined,
    onItemClick: undefined,
    onInfoClick: undefined,
    onError: (error: CometChat.CometChatException) => {
        console.log(error);
    },
    hideSeparator: true,
    activeCall: undefined,
    datePattern: DatePatterns.time,
    dateSeparatorPattern: DatePatterns.DayDate,
    callLogsStyle: {} as CallLogsStyle,
    avatarStyle: {} as AvatarStyle,
    listItemStyle: {} as ListItemStyle,
    outgoingCallConfiguration: {} as OutgoingCallConfiguration,
};

const CometChatCallLogs = (props: ICallLogsProps) => {
    const {
        title,
        titleAlignment,
        listItemView,
        subtitleView,
        tailView,
        errorStateView,
        emptyStateView,
        loadingStateView,
        emptyStateText,
        errorStateText,
        loadingIconURL,
        incomingAudioCallIconUrl,
        incomingVideoCallIconUrl,
        outgoingAudioCallIconUrl,
        outgoingVideoCallIconUrl,
        missedAudioCallIconUrl,
        missedVideoCallIconUrl,
        infoIconUrl,
        activeCall,
        callLogRequestBuilder,
        onItemClick,
        onInfoClick,
        onError,
        hideSeparator,
        datePattern,
        dateSeparatorPattern,
        callLogsStyle,
        avatarStyle,
        listItemStyle,
        outgoingCallConfiguration
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);
    const [callList, setCallList] = useState<any[]>([]);
    const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);
    const [callListState, setCallListState] = useState(States.loading);
    const [showOutgoingCallScreen, setShowOutgoingCallScreen] = useState(false);
    const [callInitiated, setCallInitiated] = useState<CometChat.Call | undefined>(undefined);
    const [call, setCall] = useState<CometChat.Call | undefined>(undefined);
    const [sessionId, setSessionId] = useState(null);
    const [showOngoingCall, setShowOngoingCall] = useState(false);
    const listenerId = "callLogsScreen_" + new Date().getTime();
    const requestBuilder = useRef<any>(null);
    const initiatedCallRef = useRef<CometChat.Call | undefined>(undefined);
    initiatedCallRef.current = callInitiated;
    const onErrorCallback = useCallback(
        (error: any) => {
            if (!(error instanceof CometChat.CometChatException)) {
                let errorModel = {
                    code: error?.code,
                    name: error?.name,
                    message: error?.message,
                    details: error?.details,
                };
                let errorObj = new CometChat.CometChatException(errorModel);
                onError?.(errorObj);
            } else {
                onError?.(error);
            }
        },
        [onError]
    );

    const setCallLogRequestBuilder = useCallback((): any => {
        try {
            if (callLogRequestBuilder) {
                return callLogRequestBuilder.build();
            } else {
                const authToken = loggedInUser!.getAuthToken();
                return new CometChatUIKitCalls.CallLogRequestBuilder()
                    .setLimit(30)
                    .setCallCategory("call")
                    .setAuthToken(authToken)
                    .build();
            }
        } catch (e) {
            onErrorCallback(e);
        }
    }, [callLogRequestBuilder, loggedInUser, onErrorCallback]);

    const fetchNextCallList = useCallback(async (): Promise<any[]> => {
        try {
            const calls = await requestBuilder?.current?.fetchNext();
            return calls;
        } catch (e) {
            onErrorCallback(e);
            throw e;
        }
    }, [requestBuilder, onErrorCallback]);

    const getCallList = useCallback(async () => {
        try {
            const calls = await fetchNextCallList();
            if (calls && calls.length) {
                setCallList((prevCallList) => {
                    return [...prevCallList, ...calls]
                })
                setCallListState(States.loaded);
            } else {
                if (callList.length === 0) {
                    setCallListState(States.empty);
                }
            }
        } catch (e) {
            if (callList.length === 0) {
                setCallListState(States.error);
            }
            onErrorCallback(e);
        }
    }, [fetchNextCallList, callList, setCallList, setCallListState, onErrorCallback])

    const cancelOutgoingCall = useCallback(() => {
        CometChat.rejectCall(call?.getSessionId()!, CometChatUIKitConstants.calls.cancelled).then(
            () => {
                setCall(undefined);
                setShowOutgoingCallScreen(false);
                CometChatMessageEvents.ccMessageSent.next({
                    message: call!,
                    status: MessageStatus.success,
                });
            })
            .catch((error: CometChat.CometChatException) => {
                setShowOutgoingCallScreen(false);
                onErrorCallback(error);
            });
    }, [call, setCall, setShowOutgoingCallScreen, onErrorCallback])
    const initiateCall = useCallback((type: string, uid: string) => {

        const receiverType: string = CometChatUIKitConstants.MessageReceiverType.user;
        const receiverId: string = uid;
        let callType: string = "";
        if (type === CometChat.CALL_TYPE.AUDIO) {
            callType = CometChat.CALL_TYPE.AUDIO;
        } else {
            callType = CometChat.CALL_TYPE.VIDEO;
        }
        const callTmp: CometChat.Call = new CometChat.Call(receiverId, callType, receiverType);
        CometChat.initiateCall(callTmp).then(
            (outgoingCall: CometChat.Call) => {
                setCallInitiated(outgoingCall)
                setCall(outgoingCall);
                setShowOutgoingCallScreen(true);
                CometChatMessageEvents.ccMessageSent.next({
                    message: outgoingCall,
                    status: MessageStatus.inprogress,
                });
            })
            .catch((error: CometChat.CometChatException) => {

                onErrorCallback(error);
            });
    }, [setCall, setShowOutgoingCallScreen, onErrorCallback, setCallInitiated])

    const handleItemClick = useCallback((call: any) => {
        try {
            if (onItemClick) {
                onItemClick(call);
            } else {
                const entity = verifyCallUser(call, loggedInUser!);
                if (entity.uid) {
                    initiateCall(call?.type, entity.uid);
                }
            }
        } catch (e) {
            onErrorCallback(e);
        }
    }, [onItemClick, loggedInUser, initiateCall, onErrorCallback])

    const handleInfoClick = useCallback((call: any) => {
        try {
            if (onInfoClick) onInfoClick(call);
        } catch (e) {
            onErrorCallback(e);
        }
    }, [onInfoClick, onErrorCallback])

    const getActiveCall = useCallback((call: any) => {
        try {
            if (activeCall) {
                if (activeCall.getSessionID() === call.getSessionID()) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            onErrorCallback(e);
            return false;
        }
    }, [activeCall, onErrorCallback])

    const getCallDirectionIcon = useCallback((call: any) => {
        try {
            const missedCall = isMissedCall(call, loggedInUser!);
            let callType = call.getType();
            if (call.getType() === CometChat.CALL_TYPE.AUDIO) {
                callType = CometChat.CALL_TYPE.AUDIO;
            } else {
                callType = CometChat.CALL_TYPE.VIDEO;
            }
            let icon;

            if (missedCall) {
                icon = callType === CometChat.CALL_TYPE.AUDIO ? missedAudioCallIconUrl : missedVideoCallIconUrl;
            } else if (isSentByMe(call, loggedInUser!)) {
                icon = callType === CometChat.CALL_TYPE.AUDIO ? outgoingAudioCallIconUrl : outgoingVideoCallIconUrl;
            } else {
                icon = callType === CometChat.CALL_TYPE.AUDIO ? incomingAudioCallIconUrl : incomingVideoCallIconUrl;
            }

            return icon;
        } catch (e) {
            onErrorCallback(e);
        }
    }, [loggedInUser, missedAudioCallIconUrl, missedVideoCallIconUrl, outgoingAudioCallIconUrl, outgoingVideoCallIconUrl, incomingAudioCallIconUrl, incomingVideoCallIconUrl, onErrorCallback])

    const isDateDifferent = useCallback((firstDate: number, secondDate: number) => {
        try {
            let firstDateObj: Date, secondDateObj: Date;
            firstDateObj = new Date(firstDate * 1000);
            secondDateObj = new Date(secondDate * 1000);
            return (
                firstDateObj.getDate() !== secondDateObj.getDate() ||
                firstDateObj.getMonth() !== secondDateObj.getMonth() ||
                firstDateObj.getFullYear() !== secondDateObj.getFullYear()
            );
        } catch (e) {
            onErrorCallback(e);
        }
    }, [onErrorCallback])

    const getMessageBubbleDate = useCallback((item: any, i: number) => {
        try {
            if (i === 0) {
                return (
                    <div style={{ margin: "5px 0", display: "flex" }}>
                        <cometchat-date timestamp={item.getInitiatedAt()} pattern={dateSeparatorPattern} dateStyle={JSON.stringify(getDateSeparator(theme, callLogsStyle!))}></cometchat-date>
                    </div>
                );
            } else {
                if (
                    isDateDifferent(callList[i - 1]?.getInitiatedAt(), item.getInitiatedAt())
                ) {
                    return (
                        <div style={{ margin: "5px 0", display: "flex" }}>
                            <cometchat-date timestamp={item.getInitiatedAt()} pattern={dateSeparatorPattern} dateStyle={JSON.stringify(getDateSeparator(theme, callLogsStyle!))}></cometchat-date>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        } catch (e) {
            onErrorCallback(e);
            return null;
        }
    }, [dateSeparatorPattern, callLogsStyle, isDateDifferent, callList, theme, onErrorCallback])

    const getOutGoingCallStyle = useCallback(() => {
        return new OutgoingCallStyle({
            background: outgoingCallConfiguration?.outgoingCallStyle?.background || theme.palette.getBackground(),
            border: outgoingCallConfiguration?.outgoingCallStyle?.border || "none",
            borderRadius: outgoingCallConfiguration?.outgoingCallStyle?.borderRadius || "8px",
            declineButtonIconBackground: outgoingCallConfiguration?.outgoingCallStyle?.declineButtonIconBackground || theme.palette.getError(),
            declineButtonIconTint: outgoingCallConfiguration?.outgoingCallStyle?.declineButtonIconTint || theme.palette.getBackground(),
            declineButtonTextColor: outgoingCallConfiguration?.outgoingCallStyle?.declineButtonTextColor || theme.palette.getAccent600(),
            declineButtonTextFont: outgoingCallConfiguration?.outgoingCallStyle?.declineButtonTextFont || fontHelper(theme.typography.caption2),
            height: outgoingCallConfiguration?.outgoingCallStyle?.height || "580px",
            width: outgoingCallConfiguration?.outgoingCallStyle?.width || "320px",
            subtitleTextColor: outgoingCallConfiguration?.outgoingCallStyle?.subtitleTextColor || theme.palette.getAccent600(),
            subtitleTextFont: outgoingCallConfiguration?.outgoingCallStyle?.subtitleTextFont || fontHelper(theme.typography.subtitle1),
            titleTextColor: outgoingCallConfiguration?.outgoingCallStyle?.titleTextColor || theme.palette.getAccent(),
            titleTextFont: outgoingCallConfiguration?.outgoingCallStyle?.titleTextFont || fontHelper(theme.typography.heading),
        });
    }, [outgoingCallConfiguration, theme])

    const getOngoingCallStyle = useCallback(() => {
        return new CallscreenStyle({
            maxHeight: "100%",
            maxWidth: "100%",
            border: "none",
            borderRadius: "0",
            background: "#1c2226",
            minHeight: "400px",
            minWidth: "400px",
            minimizeIconTint: theme.palette.getAccent900(),
            maximizeIconTint: theme.palette.getAccent900(),
        });
    }, [theme])

    const openOngoingCallScreen = useCallback((callObj: any) => {
        setShowOutgoingCallScreen(false);
        setCall(callObj);
        setSessionId(callObj?.getSessionId());
        setShowOngoingCall(true);
    }, [setShowOutgoingCallScreen, setCall, setSessionId, setShowOngoingCall])



    const attachListeners = useCallback(() => {
        try {
            CometChat.addCallListener(
                listenerId,
                new CometChat.CallListener({
                    onOutgoingCallRejected: (callObj: CometChat.Call) => {
                        if (initiatedCallRef.current && callObj.getSessionId() == initiatedCallRef.current.getSessionId()) {
                            setCall(undefined);
                            setShowOutgoingCallScreen(false);
                            setShowOngoingCall(false);
                            setCallInitiated(undefined)
                        }
                    },
                    onOutgoingCallAccepted: (callObj: CometChat.Call) => {
                        if (initiatedCallRef.current && callObj.getSessionId() == initiatedCallRef.current.getSessionId()) {
                            setCall(undefined);
                            openOngoingCallScreen(callObj);
                            setShowOutgoingCallScreen(false);
                            setCallInitiated(undefined);
                        }
                    },
                })
            );
        } catch (e) {
            onErrorCallback(e);
        }
    }, [listenerId, openOngoingCallScreen, onErrorCallback]);
    const closeCallScreen = useCallback(() => {
        setShowOngoingCall(false);
        setSessionId(null);
        setCall(undefined);
    }, [setShowOngoingCall, setSessionId, setCall])

    const subscribeToEvents = useCallback(() => {
        try {
            const ccCallEnded = CometChatCallEvents.ccCallEnded.subscribe(
                () => {
                    closeCallScreen();
                }
            );

            return () => {
                try {
                    ccCallEnded?.unsubscribe();
                } catch (error: any) {
                    onErrorCallback(error);
                }
            }
        } catch (e) {
            onErrorCallback(e);
        }
    }, [closeCallScreen, onErrorCallback])

    const detachListeners = useCallback(() => {
        try {
            CometChat.removeCallListener(listenerId);
        } catch (e) {
            onErrorCallback(e);
        }
    }, [listenerId, onErrorCallback])

    const getListItemSubtitleView = useCallback((item: any): JSX.Element => {
        if (subtitleView !== null) {
            return (
                <>
                    {subtitleView(item)}
                </>
            );
        }
        return (
            <>
                <div style={getSubtitleStyle(theme, callLogsStyle!)}>
                    <cometchat-icon URL={getCallDirectionIcon(item)} buttonStyle={JSON.stringify(getDirectionIconStyle(item, theme, callLogsStyle!, loggedInUser!))}></cometchat-icon>
                    {getCallStatusWithType(item, loggedInUser!)}
                </div>
            </>
        );
    }, [subtitleView, callLogsStyle, loggedInUser, theme, getCallDirectionIcon])

    const getListItemTailView = useCallback((item: any): JSX.Element => {
        if (tailView !== null) {
            return (
                <>
                    {tailView(item)}
                </>
            );
        }
        return (
            <>
                <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                    <cometchat-date dateStyle={JSON.stringify(getCallDateStyle(theme, callLogsStyle!))} pattern={datePattern} timestamp={item?.getInitiatedAt()} />
                    {onInfoClick ?
                        <CometChatButton
                            iconURL={infoIconUrl}
                            buttonStyle={getInfoButtonStyle(theme, callLogsStyle!)}
                            onClick={() => handleInfoClick(item)}
                        /> :
                        null
                    }

                </div>
            </>
        );
    }, [tailView, theme, callLogsStyle, datePattern, onInfoClick, infoIconUrl, handleInfoClick])

    const getListItem = useMemo(() => {
        return function (item: any, index: number): any {
            if (listItemView) {
                return listItemView(item);
            } else {
                return (
                    <>
                        {getMessageBubbleDate(item, index) ? getMessageBubbleDate(item, index) : null}
                        <CometChatListItem
                            title={verifyCallUser(item, loggedInUser!)?.getName()}
                            avatarURL={verifyCallUser(item, loggedInUser!)?.avatar || verifyCallUser(item, loggedInUser!)?.icon}
                            avatarName={verifyCallUser(item, loggedInUser!)?.getName()}
                            listItemStyle={getListItemStyle(item, theme, listItemStyle, loggedInUser!)}
                            avatarStyle={getAvatarStyle(theme, avatarStyle)}
                            hideSeparator={hideSeparator}
                            loadingIconURL={loadingIconURL}
                            isActive={getActiveCall(item)}
                            onClick={e => handleItemClick?.(item)}
                            subtitleView={getListItemSubtitleView(item)}
                            tailView={getListItemTailView(item)}
                        />
                    </>
                )
            }
        };
    }, [listItemView, getMessageBubbleDate, loggedInUser, theme, listItemStyle, avatarStyle, hideSeparator, loadingIconURL, getActiveCall, getListItemSubtitleView, getListItemTailView, handleItemClick]);


    Hooks(loggedInUser, setLoggedInUser, requestBuilder, setCallLogRequestBuilder, getCallList, attachListeners, subscribeToEvents, detachListeners, onErrorCallback);

    return (
        <div style={getContainerStyle(theme, callLogsStyle!)}>
            {
                showOutgoingCallScreen ?
                    <cometchat-backdrop>
                        <CometChatOutgoingCall
                            onCloseClicked={outgoingCallConfiguration?.onDeclineButtonClicked || cancelOutgoingCall}
                            outgoingCallStyle={getOutGoingCallStyle()}
                            call={call!}
                            avatarStyle={outgoingCallConfiguration?.avatarStyle}
                            customSoundForCalls={outgoingCallConfiguration?.customSoundForCalls}
                            customView={outgoingCallConfiguration?.customView}
                            declineButtonIconURL={outgoingCallConfiguration?.declineButtonIconURL}
                            disableSoundForCalls={outgoingCallConfiguration?.disableSoundForCalls}
                            onError={outgoingCallConfiguration?.onError}
                        />
                    </cometchat-backdrop> :
                    null
            }

            {
                showOngoingCall && !activeCall ?
                    <CometChatOngoingCall
                        sessionID={sessionId!}
                        callWorkflow={CallWorkflow.defaultCalling}
                        ongoingCallStyle={getOngoingCallStyle()}
                    /> :
                    null
            }

            <CometChatList
                hideSearch={true}
                list={callList}
                onScrolledToBottom={getCallList}
                listItemKey="getSessionID"
                listItem={getListItem}
                title={title}
                titleAlignment={titleAlignment}
                loadingIconURL={loadingIconURL}
                emptyStateText={emptyStateText}
                errorStateText={errorStateText}
                emptyStateView={emptyStateView}
                errorStateView={errorStateView}
                loadingView={loadingStateView}
                listStyle={getListStyle(theme, callLogsStyle!)}
                state={callListState}
                showSectionHeader={false}
            />
        </div>
    );
}

export { CometChatCallLogs };
