import { AvatarStyle, ListItemStyle } from "@cometchat/uikit-elements";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";
import { CallLogsStyle, ListStyle } from "@cometchat/uikit-shared";
import { isMissedCall } from "../Utils/utils";

export function getSubtitleStyle(
  theme: CometChatTheme,
  style: CallLogsStyle
): React.CSSProperties {
  return {
    display: "flex",
    color: style?.callStatusTextColor || theme.palette.getAccent600(),
    font: style?.callStatusTextFont || fontHelper(theme.typography.caption1),
    alignItems: "center",
    gap: "4px",
  };
}

export function getAvatarStyle(
  theme: CometChatTheme,
  avatarStyle?: AvatarStyle
) {
  return new AvatarStyle({
    borderRadius: avatarStyle?.borderRadius || "24px",
    width: avatarStyle?.width || "36px",
    height: avatarStyle?.height || "36px",
    border: avatarStyle?.border || "none",
    backgroundColor:
      avatarStyle?.backgroundColor || theme.palette.getAccent700(),
    nameTextColor: avatarStyle?.nameTextColor || theme.palette.getAccent900(),
    backgroundSize: avatarStyle?.backgroundSize || "cover",
    nameTextFont:
      avatarStyle?.nameTextFont || fontHelper(theme.typography.subtitle1),
    outerViewBorderWidth: avatarStyle?.outerViewBorderWidth || "",
    outerViewBorderSpacing: avatarStyle?.outerViewBorderSpacing || "",
    outerViewBorderColor: avatarStyle?.outerViewBorderColor || "",
    outerViewBorderRadius: avatarStyle?.outerViewBorderRadius || "",
  });
}

export function getListItemStyle(
  call: any,
  theme: CometChatTheme,
  listItemStyle?: ListItemStyle,
  loggedInUser?: CometChat.User
) {
  const missedCall = isMissedCall(call, loggedInUser!);
  const titleColor = missedCall
    ? theme.palette.getError()
    : theme.palette.getAccent();
  return new ListItemStyle({
    height: listItemStyle?.height || "50px",
    width: listItemStyle?.width || "100%",
    background: listItemStyle?.background || theme.palette.getBackground(),
    activeBackground:
      listItemStyle?.activeBackground || theme.palette.getAccent100(),
    borderRadius: listItemStyle?.borderRadius || "0",
    titleFont: listItemStyle?.titleFont || fontHelper(theme.typography.title2),
    titleColor: listItemStyle?.titleColor || titleColor,
    border: listItemStyle?.border || "none",
    separatorColor:
      listItemStyle?.separatorColor || theme.palette.getAccent200(),
    hoverBackground:
      listItemStyle?.hoverBackground || theme.palette.getAccent50(),
    padding: listItemStyle?.padding || "0",
  });
}

export function getContainerStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogsStyle
): React.CSSProperties {
  return {
    height: callLogsStyle?.height || "100%",
    width: callLogsStyle?.width || "100%",
    background: callLogsStyle?.background || theme.palette.getBackground(),
    borderRadius: callLogsStyle?.borderRadius || "0",
    border: callLogsStyle?.border || "none",
  };
}

export function getListStyle(
  theme: CometChatTheme,
  callLogsStyle: CallLogsStyle
): ListStyle {
  return new ListStyle({
    height: "100%",
    titleTextFont:
      callLogsStyle?.titleFont || fontHelper(theme.typography.title1),
    titleTextColor: callLogsStyle?.titleColor || theme.palette.getAccent(),
    emptyStateTextColor:
      callLogsStyle?.emptyStateTextColor || theme.palette.getAccent600(),
    emptyStateTextFont:
      callLogsStyle?.emptyStateTextFont || fontHelper(theme.typography.caption1),
    errorStateTextColor:
      callLogsStyle?.errorStateTextColor || theme.palette.getAccent600(),
    errorStateTextFont:
      callLogsStyle?.errorStateTextFont || fontHelper(theme.typography.caption1),
    loadingIconTint:
      callLogsStyle?.loadingIconTint || theme.palette.getAccent600(),
  });
}

export function getCallDateStyle(theme: CometChatTheme, style: CallLogsStyle) {
  return {
    textColor: style?.dateTextColor || theme.palette.getAccent500(),
    textFont: style?.dateTextFont || fontHelper(theme.typography.caption1),
    background: "transparent",
  };
}

export function getButtonContainerStyle(): React.CSSProperties {
  return {
    position: "absolute",
    top: "7px",
    right: "12px",
  };
}

export function getDirectionIconStyle(
  call: any,
  theme: CometChatTheme,
  style: CallLogsStyle,
  loggedInUser: CometChat.User
) {
  let tint;
  const missedCall = isMissedCall(call, loggedInUser);
  if (missedCall) {
    tint = style?.missedCallIconTint || theme.palette.getAccent600();
  } else if (call.getInitiator().getUid() === loggedInUser.getUid()) {
    tint = style?.outgoingCallIconTint || theme.palette.getAccent600();
  } else {
    tint = style?.incomingCallIconTint || theme.palette.getAccent600();
  }

  return {
    height: "18px",
    width: "18px",
    border: "none",
    borderRadius: "0",
    background: "transparent",
    buttonIconTint: tint,
  };
}

export function getInfoButtonStyle(theme: CometChatTheme, style: CallLogsStyle) {
  return {
    height: "20px",
    width: "20px",
    border: "none",
    borderRadius: "0",
    background: "transparent",
    buttonIconTint: style?.infoIconTint || theme.palette.getPrimary(),
  };
}

export function getDateSeparator(theme: CometChatTheme, style: CallLogsStyle) {
  return {
    textFont:
      style?.dateSeparatorTextFont || fontHelper(theme.typography.text2),
    textColor: style?.dateSeparatorTextColor || theme.palette.getAccent700(),
    background: "transparent",
    padding: "0",
  };
}
