import {
  AIOptionsStyle,
  CreatePollStyle,
  MessageComposerStyle,
} from "@cometchat/uikit-shared";
import {
  ActionSheetStyle,
  EmojiKeyboardStyle,
  MediaRecorderStyle,
  PopoverStyle,
  PreviewStyle,
  TextInputStyle,
} from "@cometchat/uikit-elements";
import { CometChatTheme, fontHelper } from "@cometchat/uikit-resources";

import { CSSProperties } from "react";

export const LIVE_REACTION_ICON_TINT = "red";
const RIGHT_MOST_BUTTON_HORIZONTAL_MARGIN = "10px";

export type mentionIconStyleType = CSSProperties | { buttonTextFont: string, buttonTextColor: string, buttonIconTint: string, iconBackground: string, iconHeight: string, iconWidth: string, };



export function getMessageComposerStyle(messageComposerStyle: MessageComposerStyle | undefined, theme: CometChatTheme): CSSProperties {
  return {
    width: messageComposerStyle?.width || "100%",
    height: messageComposerStyle?.height || "100%",
    boxSizing: "border-box",
    padding: "14px 16px",
    border: "none",
    borderRadius: "0",
    background: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };
}
export function previewStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme
): PreviewStyle {
  return new PreviewStyle({
    height: "100%",
    width: "100%",
    border: `1px solid ${theme.palette.getAccent200()}`,
    background: theme.palette.getBackground(),
    previewTitleFont:
      messageComposerStyle?.previewTitleFont ||
      fontHelper(theme.typography.caption1),
    previewTitleColor:
      messageComposerStyle?.previewTitleColor || theme.palette.getAccent600(),
    previewSubtitleFont:
      messageComposerStyle?.previewSubtitleFont ||
      fontHelper(theme.typography.subtitle2),
    previewSubtitleColor:
      messageComposerStyle?.previewSubtitleColor ||
      theme.palette.getAccent400(),
    closeButtonIconTint:
      messageComposerStyle?.closePreviewTint || theme.palette.getAccent600(),
    borderRadius: '12px'
  });
}



export function getMentionsViewStyle(): CSSProperties {
  return {
    maxHeight: '196px',
    overflowY: 'hidden',
    display: "flex !important",
    flexDirection: 'column',
    justifyContent: 'flex-end !important',
    zIndex: 2,
    height: "auto",
    minHeight: '45px',
    padding: '0px 0px 1px 0px',
    width: '100%',
  };
}

export function getMentionInfoIconStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  mentionsWarningStyle: React.CSSProperties
): mentionIconStyleType {
  return {
    height: "fit-content",
    width: "fit-content",
    buttonTextFont: fontHelper(theme.typography.subtitle2),
    buttonTextColor: theme.palette.getAccent600(),
    borderRadius: "8px",
    border: "none",
    buttonIconTint: theme.palette.getAccent600(),
    padding: "8px",
    iconHeight: "20px",
    iconWidth: "20px",
    iconBackground: "transparent",
    gap: "5px",
    ...mentionsWarningStyle,
  };
}

export function textInputStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme
): TextInputStyle {
  return new TextInputStyle({
    height: "100%",
    width: "100%",
    maxHeight: messageComposerStyle?.maxInputHeight || "150px",
    border:
      messageComposerStyle?.border ||
      `1px solid ${theme.palette.getAccent100()}`,
    borderRadius: messageComposerStyle?.borderRadius || "12px",
    background:
      messageComposerStyle?.background || `${theme.palette.getAccent50()}`,
    textFont:
      messageComposerStyle?.textFont || fontHelper(theme.typography.text2),
    textColor: messageComposerStyle?.textColor || theme.palette.getAccent(),
    dividerColor:
      messageComposerStyle?.dividerTint || theme.palette.getAccent100(),
    inputBorder: messageComposerStyle?.inputBorder || "none",
    inputBorderRadius: messageComposerStyle?.inputBorderRadius || "0px",
    inputBackground: messageComposerStyle?.inputBackground || "transparent",
    placeholderTextColor: theme.palette.getAccent500(),
    placeholderTextFont: fontHelper(theme.typography.subtitle2)
  });
}

export function liveReactionButtonStyle(theme: CometChatTheme, style?: MessageComposerStyle) {
  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    buttonIconTint: style?.liveReactionIconTint || "red",
    background: "transparent",
    padding: "0",
  };
}

export function sendButtonStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isDisabled: boolean = false
) {

  const buttonTint = isDisabled ? theme.palette.getAccent200() : (messageComposerStyle?.sendIconTint || theme.palette.getPrimary())

  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    buttonIconTint: buttonTint,
    background: "transparent",
    padding: "0",
  };
}

function getEmojiButtonIconTint(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isAuxiliaryContentDisplayed: boolean
): string | undefined {
  if (isAuxiliaryContentDisplayed) {
    return messageComposerStyle?.emojiIconTint || theme.palette.getAccent();
  }

  return messageComposerStyle?.emojiIconTint || theme.palette.getAccent500();
}

function getVoiceButtonIconTint(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isAuxiliaryContentDisplayed: boolean
): string | undefined {
  if (isAuxiliaryContentDisplayed) {
    return messageComposerStyle?.voiceRecordingIconTint || theme.palette.getAccent();
  }
  return messageComposerStyle?.voiceRecordingIconTint || theme.palette.getAccent500();
}

function getAttachButtonIconTint(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isSecondaryContentDisplayed: boolean
): string | undefined {
  if (isSecondaryContentDisplayed) {
    return messageComposerStyle?.attachIcontint || theme.palette.getAccent();
  }

  return messageComposerStyle?.attachIcontint || theme.palette.getAccent500();
}

function getAIButtonIconTint(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isSecondaryContentDisplayed: boolean
): string | undefined {
  if (isSecondaryContentDisplayed) {
    return messageComposerStyle?.AIIconTint || theme.palette.getAccent();
  }

  return messageComposerStyle?.AIIconTint || theme.palette.getAccent500();
}

export function liveReactionButtonDivStyle(): CSSProperties {
  return {
    //margin: `0px ${RIGHT_MOST_BUTTON_HORIZONTAL_MARGIN}`
  };
}

export function fileMediaPickerStyle(): CSSProperties {
  return {
    display: "none",
  };
}

export function secondaryViewStyle(): CSSProperties {
  return {
    flex: "1",
  };
}

export function primaryViewStyle(): CSSProperties {
  return {
    display: "flex",
    gap: "6px",
  };
}

export function auxiliaryViewStyle(): CSSProperties {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    gap: "8px",
    padding: "0",
    marginRight: "8px",
  };
}

export function createPollStyle(theme: CometChatTheme): CreatePollStyle {
  return new CreatePollStyle({
    placeholderTextFont: fontHelper(theme.typography.subtitle1),
    placeholderTextColor: theme.palette.getAccent600(),
    deleteIconTint: theme.palette.getAccent600(),
    titleFont: fontHelper(theme.typography.title1),
    titleColor: theme.palette.getAccent(),
    closeIconTint: theme.palette.getPrimary(),
    questionInputBackground: theme.palette.getAccent100(),
    optionInputBackground: theme.palette.getAccent100(),
    answerHelpTextFont: fontHelper(theme.typography.caption1),
    answerHelpTextColor: theme.palette.getAccent400(),
    addAnswerIconTint: theme.palette.getPrimary(),
    createPollButtonTextFont: fontHelper(theme.typography.text2),
    createPollButtonTextColor: theme.palette.getAccent("dark"),
    createPollButtonBackground: theme.palette.getPrimary(),
    addAnswerTextFont: fontHelper(theme.typography.text2),
    addAnswerTextColor: theme.palette.getPrimary(),
    errorTextFont: fontHelper(theme.typography.subtitle1),
    errorTextColor: theme.palette.getError(),
    optionPlaceholderTextFont: fontHelper(theme.typography.subtitle1),
    optionPlaceholderTextColor: theme.palette.getAccent600(),
    questionInputTextFont: fontHelper(theme.typography.subtitle2),
    questionInputTextColor: theme.palette.getAccent600(),
    optionInputTextFont: fontHelper(theme.typography.subtitle2),
    optionInputTextColor: theme.palette.getAccent600(),
    width: "360px",
    height: "440px",
    border: "",
    borderRadius: "8px",
    background: theme.palette.getAccent900(),
  });
}

// Default secondary view related styles

const ACTION_SHEET_HEIGHT = "244px";
const ACTION_SHEET_WIDTH = "275px";
const ACTION_SHEET_BORDER_RADIUS = "12px";

export function attachmentPopoverStyle(actionSheetStyle: ActionSheetStyle | undefined, theme: CometChatTheme): PopoverStyle {
  let height = actionSheetStyle?.height;
  let width = actionSheetStyle?.width;
  let applyActionsheetStyle = height == "100%" || width == "100%";
  return new PopoverStyle({
    width: width && !applyActionsheetStyle ? actionSheetStyle?.width : ACTION_SHEET_WIDTH,
    height: height && !applyActionsheetStyle ? actionSheetStyle?.height : ACTION_SHEET_HEIGHT,
    borderRadius: actionSheetStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    boxShadow: "0px 0px 0px 1px rgba(20, 20, 20, 0.04), 0px 16px 32px 0px rgba(20, 20, 20, 0.2)"
  });
}

export function AIPopoverStyle(
  aiOptionsStyle: AIOptionsStyle | undefined,
  theme: CometChatTheme
): PopoverStyle {
  return new PopoverStyle({
    width: aiOptionsStyle?.width || ACTION_SHEET_WIDTH,
    height: aiOptionsStyle?.height || ACTION_SHEET_HEIGHT,
    borderRadius: aiOptionsStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    boxShadow:
      "0px 0px 0px 1px rgba(20, 20, 20, 0.04), 0px 16px 32px 0px rgba(20, 20, 20, 0.2)",
  });
}

export function actionSheetContainerStyle(
  actionSheetStyle: ActionSheetStyle = {},
  theme: CometChatTheme
): CSSProperties {
  return {
    width: actionSheetStyle?.width || ACTION_SHEET_WIDTH,
    height: actionSheetStyle?.height || ACTION_SHEET_HEIGHT,
    borderRadius: actionSheetStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    border: `1px solid ${theme.palette.getAccent100()}`,
    boxSizing: "border-box",
    overflow: "auto",
  };
}

export function AIActionSheetContainerStyle(
  aiActionSheetStyle: AIOptionsStyle | undefined,
  theme: CometChatTheme
): CSSProperties {
  return {
    width: aiActionSheetStyle?.width || ACTION_SHEET_WIDTH,
    height: aiActionSheetStyle?.height || ACTION_SHEET_HEIGHT,
    borderRadius:
      aiActionSheetStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    border: `1px solid ${theme.palette.getAccent100()}`,
    boxSizing: "border-box",
    overflowY: "auto",
    overflowX: "hidden",
  };
}
export function getActionSheetStyle(actionSheetStyle: ActionSheetStyle | undefined, theme: CometChatTheme): ActionSheetStyle {
  return new ActionSheetStyle({
    width: actionSheetStyle?.width || "100%",
    height: actionSheetStyle?.height || "100%",
    border: actionSheetStyle?.border || `1px solid ${theme.palette.getAccent100()}`,
    borderRadius: actionSheetStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    background: actionSheetStyle?.background || theme.palette.getBackground(),
    layoutModeIconTint: actionSheetStyle?.layoutModeIconTint || theme.palette.getPrimary(),
    titleFont: actionSheetStyle?.titleFont || fontHelper(theme.typography.title2),
    titleColor: actionSheetStyle?.titleColor || theme.palette.getAccent(),
    listItemBackground: actionSheetStyle?.listItemBackground || 'transparent',
    listItemIconTint: actionSheetStyle?.listItemIconTint || theme.palette.getAccent700(),
    listItemIconBackground: actionSheetStyle?.listItemIconBackground || 'transparent',
    listItemIconBorderRadius: actionSheetStyle?.listItemIconBorderRadius || '0',
    ActionSheetSeparatorTint: actionSheetStyle?.ActionSheetSeparatorTint || `${theme.palette.getAccent400()}`
  });
}

export function getAIActionSheetStyle(
  aiOptionsStyle: AIOptionsStyle,
  theme: CometChatTheme
): ActionSheetStyle {
  return new ActionSheetStyle({
    width: aiOptionsStyle?.width || "100%",
    height: aiOptionsStyle?.height || "100%",
    border: aiOptionsStyle?.border || "none",
    borderRadius: aiOptionsStyle?.borderRadius || ACTION_SHEET_BORDER_RADIUS,
    background: aiOptionsStyle?.background || theme.palette.getBackground(),
    listItemBackground: aiOptionsStyle?.listItemBackground || "transparent",
    ActionSheetSeparatorTint:
      aiOptionsStyle?.optionsSeparatorTint || `${theme.palette.getAccent600()}`,
  });
}

export function attachmentButtonStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isSecondaryContentDisplayed: boolean
) {
  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    padding: "0",
    buttonIconTint: getAttachButtonIconTint(
      messageComposerStyle,
      theme,
      isSecondaryContentDisplayed
    ),
    background: "transparent",
  };
}

export function aiButtonStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isSecondaryContentDisplayed: boolean
) {
  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    padding: "0",
    buttonIconTint: getAIButtonIconTint(
      messageComposerStyle,
      theme,
      isSecondaryContentDisplayed
    ),
    background: "transparent",
  };
}

// Default auxiliary view related styles

const EMOJI_KEYBOARD_HEIGHT = "400px";
const EMOJI_KEYBOARD_WIDTH = "320px";
const EMOJI_KEYBOARD_BORDER_RADIUS = "12px";

export function emojiKeyboardPopoverStyle(): PopoverStyle {
  return new PopoverStyle({
    width: EMOJI_KEYBOARD_WIDTH,
    height: EMOJI_KEYBOARD_HEIGHT,
    borderRadius: EMOJI_KEYBOARD_BORDER_RADIUS,
    boxShadow:
      "0px 0px 0px 1px rgba(20, 20, 20, 0.04), 0px 16px 32px 0px rgba(20, 20, 20, 0.2)",
  });
}

export function emojiKeyboardContainerStyle(
  theme: CometChatTheme
): CSSProperties {
  return {
    width: EMOJI_KEYBOARD_WIDTH,
    height: EMOJI_KEYBOARD_HEIGHT,
    borderRadius: EMOJI_KEYBOARD_BORDER_RADIUS,
    border: `1px solid ${theme.palette.getAccent100()}`,
    boxSizing: "border-box",
  };
}

export function emojiKeyboardStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme
): EmojiKeyboardStyle {
  return new EmojiKeyboardStyle({
    width: "100%",
    height: "100%",
    border: "none",
    borderRadius: EMOJI_KEYBOARD_BORDER_RADIUS,
    background: theme.palette.getBackground(),
    textFont:
      messageComposerStyle?.emojiKeyboardTextFont ||
      fontHelper(theme.typography.caption1),
    textColor:
      messageComposerStyle?.emojiKeyboardTextColor || theme.palette.getAccent(),
  });
}

export function emojiButtonStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isAuxiliaryContentDisplayed: boolean,
  applyHorizontalMargin: boolean = false
) {

  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    buttonIconTint: getEmojiButtonIconTint(
      messageComposerStyle,
      theme,
      isAuxiliaryContentDisplayed
    ),
    background: "transparent",
    padding: "0",
  };
}

const VOICE_RECORDING_HEIGHT = "100px";
const VOICE_RECORDING_WIDTH = "250px";
const VOICE_RECORDING_BORDER_RADIUS = "8px";

export function voiceRecordingPopoverStyle(): PopoverStyle {
  return new PopoverStyle({
    width: VOICE_RECORDING_WIDTH,
    height: VOICE_RECORDING_HEIGHT,
    borderRadius: VOICE_RECORDING_BORDER_RADIUS,
    border: "1px solid rgba(20, 20, 20, 0.08)",
    boxShadow:
      "0px 0px 0px 1px rgba(20, 20, 20, 0.04), 0px 16px 32px 0px rgba(20, 20, 20, 0.2)",
  });
}

export function voiceRecordingContainerStyle(
  theme: CometChatTheme
): CSSProperties {
  return {
    width: VOICE_RECORDING_WIDTH,
    height: VOICE_RECORDING_HEIGHT,
    // maxWidth: "80vw",
    borderRadius: VOICE_RECORDING_BORDER_RADIUS,
    boxSizing: "border-box",
  };
}

export function voiceRecordingStyle(
  mediaRecorderStyle: MediaRecorderStyle | undefined,
  theme: CometChatTheme
): EmojiKeyboardStyle {
  return new MediaRecorderStyle({
    ...mediaRecorderStyle,
    background: mediaRecorderStyle?.background || theme.palette.getBackground(),
    border: "1px solid rgba(20, 20, 20, 0.08)",
    borderRadius: VOICE_RECORDING_BORDER_RADIUS,
    closeIconTint:
      mediaRecorderStyle?.closeIconTint || theme.palette.getAccent600(),
    submitIconTint:
      mediaRecorderStyle?.submitIconTint || theme.palette.getAccent600(),
    startIconTint:
      mediaRecorderStyle?.startIconTint || theme.palette.getError(),
    stopIconTint: mediaRecorderStyle?.stopIconTint || theme.palette.getError(),
    timerTextColor: mediaRecorderStyle?.timerTextColor || theme.palette.getAccent400(),
    timerTextFont: mediaRecorderStyle?.timerTextFont || fontHelper(theme.typography.text3),
  });
}

export function voiceButtonStyle(
  messageComposerStyle: MessageComposerStyle | undefined,
  theme: CometChatTheme,
  isAuxiliaryContentDisplayed: boolean,
  applyHorizontalMargin: boolean = false
) {
  const marginStyle: { margin?: string } = {};

  if (applyHorizontalMargin) {
    marginStyle.margin = `0px ${RIGHT_MOST_BUTTON_HORIZONTAL_MARGIN}`;
  }

  return {
    height: "24px",
    width: "24px",
    border: "none",
    borderRadius: "0",
    buttonIconTint: getVoiceButtonIconTint(
      messageComposerStyle,
      theme,
      isAuxiliaryContentDisplayed
    ),
    background: "transparent",
    ...marginStyle,
  };
}

export function defaultAuxiliaryViewContainer(): CSSProperties {
  return {
    display: "flex",
    flex: "1",
  };
}

export function messageComposerHeaderStyle(): CSSProperties {
  return {
    height: "fit-content",
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    left: "50%",
    transform: "translate(-50%)",
    zIndex: 1,
    padding: '0px 0px 1px 0px'
  };
}
