import React, { useContext } from 'react';
import { CometChatThemeContext } from '../../CometChatThemeContext';
import { CometChatActionSheet } from '@cometchat/uikit-elements';
import { AIAssistBotConfiguration } from '@cometchat/uikit-shared';
import { CometChatMessageComposerAction } from '@cometchat/uikit-resources';
import { getBackButtonStyle, getBotTitleStyle, getBotsContainerStyle, getContainerStyle, getOptionStyle, getOptionsContainerStyle } from './style';
import { createComponent } from "@lit-labs/react";
import backIcon from '../assets/backbutton.svg';

interface IAIAssistBotProps {
    title?: string
    bots?: CometChatMessageComposerAction[]
    closeCallback?: () => void
    backCallback?: () => void
    configuration?: AIAssistBotConfiguration
};

const defaultProps: IAIAssistBotProps = {
    title: undefined,
    bots: undefined,
    closeCallback: undefined,
    backCallback: undefined,
    configuration: undefined
}

const ActionSheet = createComponent({
    tagName: 'cometchat-action-sheet',
    elementClass: CometChatActionSheet,
    react: React,
    events: {
        'onClick': 'cc-actionsheet-clicked',
    }
})

const AIAssistBotOptoinsView = (props: IAIAssistBotProps) => {
    const {
        title,
        bots,
        closeCallback,
        backCallback,
        configuration,
    } = { ...defaultProps, ...props };
    const { theme } = useContext(CometChatThemeContext);

    const onBotClick = (event: any) => {
        const bot: CometChatMessageComposerAction = event?.detail?.action;
        if (bot.onClick) {
            bot.onClick();
        }
        if (closeCallback) {
            closeCallback();
        }
    }

    return (
        <div className="slideable-container" style={getContainerStyle(configuration?.assistBotStyle, theme)}>
            <div style={getBotsContainerStyle()}>
                <div style={getBotTitleStyle(configuration?.assistBotStyle, theme)}>
                    <div>
                        <cometchat-button iconURL={configuration?.backIconURL || backIcon} onClick={() => backCallback ? backCallback() : null} buttonStyle={JSON.stringify(getBackButtonStyle(configuration?.assistBotStyle, theme))} />
                    </div>
                    <div>
                        {title}
                    </div>
                    <div style={{ flex: .2 }}></div>
                </div>
                <div style={getOptionsContainerStyle()}>
                    <ActionSheet
                        actions={bots}
                        hideLayoutMode={true}
                        actionSheetStyle={getOptionStyle(configuration?.assistBotStyle, theme)}
                        onClick={onBotClick} />
                </div>
            </div>
        </div>
    )
};

export default AIAssistBotOptoinsView;
