import "@cometchat/uikit-elements";

import {
  CometChatGroupEvents,
  CometChatMessageEvents,
  CometChatUIEvents,
  CometChatUserEvents,
  IGroupLeft,
  PanelAlignment,
} from "@cometchat/uikit-resources";
import {
  DetailsConfiguration,
  MessageComposerConfiguration,
  MessageHeaderConfiguration,
  MessageListConfiguration,
  MessagesStyle,
  ThreadedMessagesConfiguration,
} from "@cometchat/uikit-shared";
import {
  MessagesComposerDivStyle,
  MessagesDetailsDivStyle,
  MessagesDivStyle,
  MessagesHeaderDivStyle,
  MessagesListDivStyle,
  MessagesWrapperStyle,
  ThreadedMessagesDivStyle,
  detailsButtonStyle,
  getDetailsStyle,
  getMessageComposerStyle,
  getMessageHeaderStyle,
  getMessageListStyle,
  liveReactionStyle,
  panelDivStyle,
} from "./style";
import { useCallback, useContext, useMemo, useRef, useState } from "react";

import { ChatConfigurator } from "../Shared/Framework/ChatConfigurator";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatDetails } from "../CometChatDetails";
import { CometChatMessageComposer } from "../CometChatMessageComposer";
import { CometChatMessageHeader } from "../CometChatMessageHeader";
import { CometChatMessageList } from "../CometChatMessageList/CometChatMessageList";
import { CometChatThemeContext } from "../CometChatThemeContext";
import { CometChatThreadedMessages } from "../CometChatThreadedMessages";
import { Hooks } from "./hooks";
import InfoIcon from "./assets/Info.svg";
import LiveReactionIcon from "./assets/heart-reaction.png";

interface IMessagesProps {
  user?: CometChat.User;
  group?: CometChat.Group;
  hideMessageComposer?: boolean;
  disableTyping?: boolean;
  messageHeaderConfiguration?: MessageHeaderConfiguration;
  messageListConfiguration?: MessageListConfiguration;
  messageComposerConfiguration?: MessageComposerConfiguration;
  threadedMessagesConfiguration?: ThreadedMessagesConfiguration;
  detailsConfiguration?: DetailsConfiguration;
  customSoundForIncomingMessages?: string;
  customSoundForOutgoingMessages?: string;
  disableSoundForMessages?: boolean;
  messagesStyle?: MessagesStyle;
  messageHeaderView?: any;
  messageComposerView?: any;
  messageListView?: any;
  hideMessageHeader?: boolean;
  hideDetails?: boolean;
  auxiliaryMenu?: any;
}

const defaultProps: IMessagesProps = {
  user: undefined,
  group: undefined,
  hideMessageComposer: false,
  disableTyping: false,
  messageHeaderConfiguration: new MessageHeaderConfiguration({}),
  messageListConfiguration: new MessageListConfiguration({}),
  messageComposerConfiguration: new MessageComposerConfiguration({}),
  threadedMessagesConfiguration: new ThreadedMessagesConfiguration({}),
  detailsConfiguration: new DetailsConfiguration({}),
  customSoundForIncomingMessages: "",
  customSoundForOutgoingMessages: "",
  disableSoundForMessages: false,
  messagesStyle: new MessagesStyle({
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: "none",
    border: "1px solid rgba(20, 20, 20, 0.1)"
  }),
  messageHeaderView: null,
  messageComposerView: null,
  messageListView: null,
  hideMessageHeader: false,
  hideDetails: false,
  auxiliaryMenu: null,
};



const CometChatMessages = (props: IMessagesProps) => {
  const { theme } = useContext(CometChatThemeContext);

  const {
    user,
    group,
    hideMessageComposer,
    disableTyping,
    messageHeaderConfiguration,
    messageListConfiguration,
    messageComposerConfiguration,
    threadedMessagesConfiguration,
    detailsConfiguration,
    customSoundForIncomingMessages,
    customSoundForOutgoingMessages,
    disableSoundForMessages,
    messagesStyle,
    messageHeaderView,
    messageComposerView,
    messageListView,
    hideMessageHeader,
    hideDetails,
    auxiliaryMenu,
  } = { ...defaultProps, ...props };
  const liveReactionName = "heart";
  const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);

  const [activeUser, setActiveUser] = useState(user);
  const [activeGroup, setActiveGroup] = useState(group);

  const ccHeaderMenuRef = useRef(null);
  const threadMessageObjectRef = useRef<CometChat.BaseMessage | null>(null);
  const parentBubbleViewCallbackRef = useRef<Function | null>(null);

  const [liveReaction, setLiveReaction] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openThreadedMessages, setOpenThreadedMessages] = useState(false);

  const [showPanelView, setShowPanelView] = useState(false);
  const assistBotChatView = useRef(null);

  let messagesRequestBuilder = useMemo(() => {
    if (user) {
      return new CometChat.MessagesRequestBuilder()
        .setUID(user.getUid())
        .setCategories(
          ChatConfigurator.getDataSource().getAllMessageCategories()
        )
        .setTypes(ChatConfigurator.getDataSource().getAllMessageTypes())
        .setLimit(20)
        .hideReplies(true);
    } else if (group) {
      return new CometChat.MessagesRequestBuilder()
        .setGUID(group.getGuid())
        .setCategories(
          ChatConfigurator.getDataSource().getAllMessageCategories()
        )
        .setTypes(ChatConfigurator.getDataSource().getAllMessageTypes())
        .setLimit(20)
        .hideReplies(true);
    }
  }, [user, group]);

  let reactionName: string = messageComposerConfiguration?.LiveReactionIconURL || LiveReactionIcon,
    infoIconURL: string = InfoIcon;

  const liveReactionStart = useCallback(
    (reactionName: string) => {
      if (liveReaction) {
        reactionName = "";
        setLiveReaction(false);
      } else {
        setLiveReaction(true);
        setTimeout(() => {
          reactionName = "";
          setLiveReaction(false);
        }, 1500);
      }
    },
    [liveReaction, setLiveReaction]
  );

  const subscribeToEvents = useCallback(() => {
    try {

      const ccLiveReaction = CometChatMessageEvents.ccLiveReaction.subscribe(
        (reactionName: any) => {
          if (!openThreadedMessages && reactionName && reactionName === liveReactionName) {
            liveReactionStart(reactionName);
          }
        }
      );
      const ccGroupDeleted = CometChatGroupEvents.ccGroupDeleted.subscribe(
        (value: CometChat.Group) => {
          if (activeGroup && activeGroup.getGuid() === group?.getGuid()) {
            setOpenDetails(false);
            setOpenThreadedMessages(false);
            setActiveGroup(value);
          }
        }
      );
      const ccGroupLeft = CometChatGroupEvents.ccGroupLeft.subscribe(
        (item: IGroupLeft) => {
          if (activeGroup?.getGuid() === item.leftGroup.getGuid()) {
            if (loggedInUser?.getUid() === item?.userLeft.getUid()) {
              setOpenDetails(false);
              setOpenThreadedMessages(false);
            }
            setActiveGroup(item.leftGroup);
          }
        }
      );
      const ccUserBlocked = CometChatUserEvents.ccUserBlocked.subscribe(
        (blockedUser: CometChat.User) => {
          if (activeUser?.getUid() === blockedUser.getUid()) {
            blockedUser.setBlockedByMe(true);
            setActiveUser(blockedUser);
          }
        }
      );
      const ccUserUnBlocked = CometChatUserEvents.ccUserUnblocked.subscribe(
        (unblockedUser: CometChat.User) => {
          if (activeUser?.getUid() === unblockedUser.getUid()) {
            unblockedUser.setBlockedByMe(false);
            setActiveUser(unblockedUser);
          }
        }
      );

      const ccShowPanel = CometChatUIEvents.ccShowPanel.subscribe((data) => {
        if (data.position === PanelAlignment.messages) {
          assistBotChatView.current = data.child;
          setShowPanelView(true);
        }
      });

      const ccHidePanel = CometChatUIEvents.ccHidePanel.subscribe(
        (alignment) => {
          if (alignment === PanelAlignment.messages) {
            assistBotChatView.current = null;
            setShowPanelView(false);
          }
        }
      );

      return () => {
        try {
          ccLiveReaction?.unsubscribe();
          ccGroupDeleted?.unsubscribe();
          ccGroupLeft?.unsubscribe();
          ccUserBlocked?.unsubscribe();
          ccUserUnBlocked?.unsubscribe();
          ccShowPanel?.unsubscribe();
          ccHidePanel?.unsubscribe();
        } catch (error: any) {
          console.log("error", error);
        }
      };
    } catch (error: any) {
      console.log("error", error);
    }
  }, [
    openThreadedMessages,
    liveReactionStart,
    setOpenDetails,
    setOpenThreadedMessages,
    activeGroup,
    activeUser,
    loggedInUser,
    group,
  ]);

  const openThreadView = (
    message: CometChat.BaseMessage,
    callback: Function
  ) => {
    threadMessageObjectRef.current = message;
    parentBubbleViewCallbackRef.current = callback;
    setOpenThreadedMessages(true);
  };

  const openDetailsPage = () => setOpenDetails(true);
  const closeDetailsPage = () => {
    setOpenDetails(false);
  };

  const closeThreadView = () => {
    threadMessageObjectRef.current = null;
    parentBubbleViewCallbackRef.current = null;
    setOpenThreadedMessages(false);
  };
  function getDetailsTemplate() {
    if (detailsConfiguration?.data) {
      return detailsConfiguration?.data(user, group);
    }
    return;
  }

  const getHeaderMenu = useCallback(() => {
    if (messageHeaderConfiguration?.menu) {
      return messageHeaderConfiguration.menu(activeUser, activeGroup);
    }
    const defaultAuxiliaryMenu =
      ChatConfigurator.getDataSource().getAuxiliaryHeaderMenu(
        activeUser,
        activeGroup
      );
    return (
      <>
        {auxiliaryMenu
          ? auxiliaryMenu
          : defaultAuxiliaryMenu.map((auxMenu: any) => auxMenu)}
        {!hideDetails ? (
          <div
            className='cc-messages__header-menu-wrapper'
            style={{
              height: "100%",
              width: "100%",
              border: "none",
              background: "transparent",
              borderRadius: 0,
              marginLeft: "16px",
            }}
          >
            <cometchat-button
              iconURL={infoIconURL}
              buttonStyle={JSON.stringify(detailsButtonStyle(theme))}
              ref={ccHeaderMenuRef}
              onClick={openDetailsPage}
            ></cometchat-button>
          </div>
        ) : null}
      </>
    );
  }, [
    messageHeaderConfiguration,
    activeUser,
    activeGroup,
    auxiliaryMenu,
    hideDetails,
    infoIconURL,
  ]);

  const getMessageHeaderComponent = () => {
    return (
      <CometChatMessageHeader
        user={activeUser}
        group={activeGroup}
        subtitleView={messageHeaderConfiguration?.subtitleView ? messageHeaderConfiguration.subtitleView(activeUser, activeGroup) : null}
        disableUsersPresence={messageHeaderConfiguration?.disableUsersPresence}
        protectedGroupIcon={messageHeaderConfiguration?.protectedGroupIcon}
        passwordGroupIcon={messageHeaderConfiguration?.passwordGroupIcon}
        privateGroupIcon={messageHeaderConfiguration?.privateGroupIcon}
        menu={getHeaderMenu()}
        avatarStyle={messageHeaderConfiguration?.avatarStyle}
        statusIndicatorStyle={messageHeaderConfiguration?.statusIndicatorStyle}
        messageHeaderStyle={getMessageHeaderStyle(messageHeaderConfiguration)}
        listItemStyle={messageHeaderConfiguration?.listItemStyle}
        backButtonIconURL={messageHeaderConfiguration?.backButtonIconURL}
        hideBackButton={messageHeaderConfiguration?.hideBackButton}
        listItemView={messageHeaderConfiguration?.listItemView ? messageHeaderConfiguration.listItemView(activeUser, activeGroup) : null}
        onError={messageHeaderConfiguration?.onError}
        onBack={messageHeaderConfiguration?.onBack}
        disableTyping={disableTyping}
      />
    );
  };

  const getMessageHeader = () => {
    if (hideMessageHeader) {
      return null;
    }

    return (
      <div className='cc-messages__header' style={MessagesHeaderDivStyle}>
        {messageHeaderView ? messageHeaderView : getMessageHeaderComponent()}
      </div>
    );
  };

  const getMessageListComponent = () => {
    return (
      <CometChatMessageList
        loadingIconURL={messageListConfiguration?.loadingIconURL}
        user={activeUser}
        group={activeGroup}
        emptyStateView={messageListConfiguration?.emptyStateView}
        errorStateView={messageListConfiguration?.errorStateView}
        loadingStateView={messageListConfiguration?.loadingStateView}
        disableSoundForMessages={disableSoundForMessages}
        customSoundForMessages={customSoundForIncomingMessages}
        disableReceipt={messageListConfiguration?.disableReceipt}
        hideReceipt={messageListConfiguration?.hideReceipt}
        readIcon={messageListConfiguration?.readIcon}
        deliveredIcon={messageListConfiguration?.deliveredIcon}
        sentIcon={messageListConfiguration?.sentIcon}
        waitIcon={messageListConfiguration?.waitIcon}
        errorIcon={messageListConfiguration?.errorIcon}
        alignment={messageListConfiguration?.alignment}
        showAvatar={messageListConfiguration?.showAvatar}
        scrollToBottomOnNewMessages={
          messageListConfiguration?.scrollToBottomOnNewMessages
        }
        datePattern={messageListConfiguration?.datePattern}
        timestampAlignment={messageListConfiguration?.timestampAlignment}
        DateSeparatorPattern={messageListConfiguration?.DateSeparatorPattern}
        templates={messageListConfiguration?.templates}
        messagesRequestBuilder={
          messageListConfiguration?.messagesRequestBuilder
        }
        thresholdValue={messageListConfiguration?.thresholdValue}
        onThreadRepliesClick={
          messageListConfiguration?.onThreadRepliesClick || openThreadView
        }
        headerView={messageListConfiguration?.headerView}
        footerView={messageListConfiguration?.footerView}
        avatarStyle={messageListConfiguration?.avatarStyle}
        dateSeparatorStyle={messageListConfiguration?.dateSeparatorStyle}
        messageListStyle={getMessageListStyle(messageListConfiguration)}
        onError={messageListConfiguration?.onError}
        disableMentions={messageListConfiguration?.disableMentions}
        hideDateSeparator={messageListConfiguration?.hideDateSeparator}
        reactionsConfiguration={
          messageListConfiguration?.reactionsConfiguration
        }
        disableReactions={messageListConfiguration?.disableReactions}
        emojiKeyboardStyle={messageListConfiguration?.emojiKeyboardStyle}
        threadIndicatorIcon={messageListConfiguration?.threadIndicatorIcon}
        textFormatters={messageListConfiguration?.textFormatters}
        messageInformationConfiguration={messageListConfiguration?.messageInformationConfiguration}
        backdropStyle={messageListConfiguration?.backdropStyle}
        hideError={messageListConfiguration?.hideError}
      />
    );
  };

  const getMessageList = () => {
    if (messageListView) {
      return messageListView;
    }

    return (
      <div className='cc-messages__list' style={MessagesListDivStyle}>
        {getMessageListComponent()}
      </div>
    );
  };

  const getMessageComposerComponent = () => {
    return (
      !hideMessageComposer ?
        <CometChatMessageComposer

          sendButtonIconURL={messageComposerConfiguration?.sendButtonIconURL}
          user={activeUser}
          group={activeGroup}
          text={messageComposerConfiguration?.text}
          headerView={messageComposerConfiguration?.headerView ? messageComposerConfiguration.headerView(activeUser, activeGroup) : null}
          onTextChange={messageComposerConfiguration?.onTextChange}
          attachmentIconURL={messageComposerConfiguration?.attachmentIconURL}
          attachmentOptions={messageComposerConfiguration?.attachmentOptions}
          secondaryButtonView={messageComposerConfiguration?.secondaryButtonView}
          auxiliaryButtonView={messageComposerConfiguration?.auxilaryButtonView}
          auxiliaryButtonAlignment={
            messageComposerConfiguration?.auxiliaryButtonsAlignment
          }
          sendButtonView={messageComposerConfiguration?.sendButtonView}
          emojiIconURL={messageComposerConfiguration?.emojiIconURL}
          hideLiveReaction={messageComposerConfiguration?.hideLiveReaction}
          LiveReactionIconURL={messageComposerConfiguration?.LiveReactionIconURL}
          hideLayoutMode={messageComposerConfiguration?.hideLayoutMode}
          actionSheetStyle={messageComposerConfiguration?.actionSheetStyle}
          messageComposerStyle={getMessageComposerStyle(
            messageComposerConfiguration
          )}
          onSendButtonClick={messageComposerConfiguration?.onSendButtonClick}
          onError={messageComposerConfiguration?.onError}
          disableSoundForMessages={disableSoundForMessages}
          customSoundForMessage={customSoundForOutgoingMessages}
          hideVoiceRecording={messageComposerConfiguration?.hideVoiceRecording}
          voiceRecordingStartIconURL={
            messageComposerConfiguration?.voiceRecordingStartIconURL
          }
          voiceRecordingCloseIconURL={
            messageComposerConfiguration?.voiceRecordingCloseIconURL
          }
          voiceRecordingStopIconURL={
            messageComposerConfiguration?.voiceRecordingStopIconURL
          }
          voiceRecordingSubmitIconURL={
            messageComposerConfiguration?.voiceRecordingSubmitIconURL
          }
          AIOptionsStyle={messageComposerConfiguration?.AIOptionsStyle}
          AIIconURL={messageComposerConfiguration?.AIIconURL}
          userMemberWrapperConfiguration={
            messageComposerConfiguration?.userMemberWrapperConfiguration
          }
          textFormatters={
            messageComposerConfiguration?.textFormatters || []
          }
          disableMentions={messageComposerConfiguration?.disableMentions}
          mentionsWarningText={
            messageComposerConfiguration?.mentionsWarningText
          }
          mentionsWarningStyle={
            messageComposerConfiguration?.mentionsWarningStyle
          }
          mediaRecorderStyle={messageComposerConfiguration?.mediaRecorderStyle}
        /> : null
    )
  };

  const getMessageComposer = () => {
    if (hideMessageComposer) {
      return null;
    }

    if (messageComposerView) {
      return messageComposerView;
    }

    return (
      <div
        className='cc-messages__composer'
        style={MessagesComposerDivStyle(theme)}
      >
        {getMessageComposerComponent()}
      </div>
    );
  };

  const getThreadedMessagesComponent = () => {
    if (openThreadedMessages && threadMessageObjectRef.current) {
      return (
        <CometChatThreadedMessages
          parentMessage={threadMessageObjectRef.current}
          onClose={threadedMessagesConfiguration?.onClose || closeThreadView}
          onError={threadedMessagesConfiguration?.onError}
          closeIconURL={threadedMessagesConfiguration?.closeIconURL}
          bubbleView={
            threadedMessagesConfiguration?.bubbleView ||
            parentBubbleViewCallbackRef.current
          }
          messageActionView={threadedMessagesConfiguration?.messageActionView ? threadedMessagesConfiguration.messageActionView(threadMessageObjectRef.current) : null}
          messageListConfiguration={
            threadedMessagesConfiguration?.messageListConfiguration
          }
          messageComposerConfiguration={
            threadedMessagesConfiguration?.messageComposerConfiguration
          }
          threadedMessagesStyle={
            threadedMessagesConfiguration?.threadedMessagesStyle
          }
          hideMessageComposer={threadedMessagesConfiguration?.hideMessageComposer}
          messageComposerView={threadedMessagesConfiguration?.messageComposerView}
          messageListView={threadedMessagesConfiguration?.messageListView}

        />
      );
    }

    return null;
  };

  const getThreadedMessages = () => {
    if (openThreadedMessages && threadMessageObjectRef.current) {
      return (
        <div className='cc-messages__threaded' style={ThreadedMessagesDivStyle}>
          {getThreadedMessagesComponent()}
        </div>
      );
    }
    return null;
  };
  const getLiveReactionStyle = () => {
    return {
      height: "20px",
      width: "25px",
      background: messageComposerConfiguration?.messageComposerStyle?.liveReactionIconTint || "red"
    }
  }

  const getLiveReaction = () => {
    return liveReaction ? (
      <div className='cc-messages__live-reaction' style={liveReactionStyle()}>
        <cometchat-live-reaction
          reactionIconURL={reactionName}
          liveReactionStyle={JSON.stringify(getLiveReactionStyle())}
        ></cometchat-live-reaction>
      </div>
    ) : null;
  };

  const getDetailsComponent = () => {
    return (
      <CometChatDetails
        user={activeUser}
        group={activeGroup}
        closeButtonIconURL={
          detailsConfiguration?.closeButtonIconURL || "./assets/close2x.svg"
        }
        hideProfile={detailsConfiguration?.hideProfile}
        subtitleView={detailsConfiguration?.subtitleView}
        customProfileView={detailsConfiguration?.customProfileView}
        data={getDetailsTemplate()}
        disableUsersPresence={detailsConfiguration?.disableUsersPresence}
        privateGroupIcon={detailsConfiguration?.privateGroupIcon}
        protectedGroupIcon={detailsConfiguration?.protectedGroupIcon}
        passwordGroupIcon={detailsConfiguration?.passwordGroupIcon}
        onError={detailsConfiguration?.onError}
        onClose={detailsConfiguration?.onClose || closeDetailsPage}
        leaveDialogStyle={detailsConfiguration?.leaveDialogStyle}
        statusIndicatorStyle={detailsConfiguration?.statusIndicatorStyle}
        avatarStyle={detailsConfiguration?.avatarStyle}
        detailsStyle={getDetailsStyle(
          detailsConfiguration,
          messagesStyle,
          theme
        )}
        listItemStyle={detailsConfiguration?.listItemStyle}
        groupMembersConfiguration={
          detailsConfiguration?.groupMembersConfiguration
        }
        addMembersConfiguration={detailsConfiguration?.addMembersConfiguration}
        bannedMembersConfiguration={
          detailsConfiguration?.bannedMembersConfiguration
        }
        transferOwnershipConfiguration={
          detailsConfiguration?.transferOwnershipConfiguration
        }
      />
    );
  };

  const getDetails = () => {
    return openDetails ? (
      <div className='cc-messages__details' style={MessagesDetailsDivStyle}>
        {getDetailsComponent()}
      </div>
    ) : null;
  };

  Hooks(
    loggedInUser,
    setLoggedInUser,
    subscribeToEvents,
    ccHeaderMenuRef,
    setOpenDetails,
    messageListConfiguration,
    user ?? null,
    setActiveUser,
    group ?? null,
    setActiveGroup,
    setOpenThreadedMessages
  );

  if (!activeUser && !activeGroup) {
    return null;
  }

  return (
    <div
      className='cc-messages-wrapper'
      style={MessagesWrapperStyle(messagesStyle, theme)}
    >
      {showPanelView && assistBotChatView.current ? (
        <div className='cc-messages-wrapper__panel' style={panelDivStyle}>
          {assistBotChatView.current}{" "}
        </div>
      ) : null}
      <div className='cc-messages' style={MessagesDivStyle()}>
        {getMessageHeader()}
        {getMessageList()}
        {getMessageComposer()}
      </div>
      {getThreadedMessages()}
      {getLiveReaction()}
      {getDetails()}
    </div>
  );
};

export { CometChatMessages };
