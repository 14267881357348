import { AIConversationSummaryConfiguration, CometChatAICard, SmartReplies } from '@cometchat/uikit-shared';
import { CometChatTheme, States, localize } from '@cometchat/uikit-resources';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { contentContainerStyle, getContainerStyle, getConversationSummaryStyle, getPanelStyle } from './style';

import Close2xIcon from '../assets/close2x.svg'
import { CometChatPanel } from '@cometchat/uikit-elements';
import { CometChatThemeContext } from '../../CometChatThemeContext';
import { createComponent } from "@lit-labs/react";
import emptyIcon from '../assets/ai-empty.svg';
import errorIcon from '../assets/ai-error.svg';
import loadingIconURL from '../assets/loading.svg';

interface IAIConversationSummaryProps {
    getConversationSummaryCallback?: (theme?: CometChatTheme) => Promise<string>
    editReplyCallback?: (reply: string) => void
    closeCallback?: () => void
    configuration?: AIConversationSummaryConfiguration
};

const defaultProps: IAIConversationSummaryProps = {
    getConversationSummaryCallback: undefined,
    editReplyCallback: undefined,
    closeCallback: undefined,
    configuration: undefined
}

const CometChatConversationSummaryView = createComponent({
    tagName: 'smart-replies',
    elementClass: SmartReplies,
    react: React,
    events: {
        'ccReplyClicked': 'cc-reply-clicked',
        'ccCloseClicked': 'cc-close-clicked'
    }
});

const CometChatAICardView = createComponent({
    tagName: 'cometchat-ai-card',
    elementClass: CometChatAICard,
    react: React
});

const CometChatPanelView = createComponent({
    tagName: 'cometchat-panel',
    elementClass: CometChatPanel,
    react: React,
    events: {
        'ccCloseClicked': 'cc-close-clicked'
    }
});

const AIConversationSummaryView = (props: IAIConversationSummaryProps) => {
    const {
        getConversationSummaryCallback,
        editReplyCallback,
        closeCallback,
        configuration,
    } = { ...defaultProps, ...props };
    const [messageListState, setMessageListState] = useState<States>(States.loading);
    const [activeView, setActiveView] = useState<JSX.Element | null>(null);

    const { theme } = useContext(CometChatThemeContext)

    const errorStateText: string = localize("SOMETHING_WRONG");
    const emptyStateText: string = localize("NO_MESSAGES_FOUND");
    const loadingStateText: string = localize("GENERATING_SUMMARY");
    const titleText: string = localize("CONVERSATION_SUMMARY");

    useEffect(() => {
        fetchButtonContent();
    }, []);

    function fetchButtonContent() {
        setMessageListState(States.loading);
        if (props && getConversationSummaryCallback) {
            getConversationSummaryCallback(theme).then(async (response) => {
                if (response) {
                    setMessageListState(States.loaded);
                    setActiveView(await getLoadedView(response));
                } else {
                    setMessageListState(States.empty);
                }
            })
                .catch((err) => {
                    setMessageListState(States.error);
                })
        }
    }

    /**
     * Create a view based on the value of the `state` prop.
     */
    function getStateView(): JSX.Element | null {
        let res: JSX.Element | null = null;
        switch (messageListState) {
            case States.loading:
                res = getLoadingView();
                break;
            case States.error:
                res = getErrorView();
                break;
            case States.empty:
                res = getEmptyView();
                break;
            case States.loaded:
                break;
            default:
                const x: never = messageListState;
        }
        return res;
    }

    /**
     * Creates the loading view
     */
    function getLoadingView(): JSX.Element {
        let LoadingView = configuration?.loadingStateView;
        return (
            <CometChatAICardView
                state={States.loading}
                style={configuration?.conversationSummaryStyle}
                loadingIconURL={configuration?.loadingIconURL || loadingIconURL}
                loadingStateText={loadingStateText}
            >
                {LoadingView ? <div slot='loadingView'>{<LoadingView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the error view
     */
    function getErrorView(): JSX.Element | null {
        let ErrorView = configuration?.errorStateView;
        return (
            <CometChatAICardView
                state={States.error}
                style={configuration?.conversationSummaryStyle}
                errorIconURL={configuration?.errorIconURL || errorIcon}
                errorStateText={errorStateText}
            >
                {ErrorView ? <div slot='errorView'>{<ErrorView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the empty view
     */
    function getEmptyView(): JSX.Element {
        let EmptyView = configuration?.emptyStateView;
        return (
            <CometChatAICardView
                state={States.empty}
                style={configuration?.conversationSummaryStyle}
                emptyIconURL={configuration?.emptyIconURL || emptyIcon}
                emptyStateText={emptyStateText}
            >
                {EmptyView ? <div slot='errorView'>{<EmptyView />}</div> : null}
            </CometChatAICardView>
        );
    }

    /**
     * Creates the loaded view
     */
    async function getLoadedView(conversationSummary: string): Promise<JSX.Element> {
        return new Promise((resolve, reject) => {
            try {
                let CustomView = configuration?.customView;

                if (CustomView) {
                    configuration?.customView!(conversationSummary, closeCallback).then((res: any) => {
                        return resolve(res);
                    })
                        .catch((err: CometChat.CometChatException) => {
                            return reject(err)
                        })
                } else {
                    let conversationSummaryView = <CometChatPanelView
                        panelStyle={getPanelStyle(theme, configuration?.conversationSummaryStyle!)}
                        title={titleText}
                        text={conversationSummary}
                        closeIconUrl={configuration?.closeIconURL || Close2xIcon}
                        ccCloseClicked={() => closeCallback!()}
                    />
                    return resolve(conversationSummaryView);
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    return (
        <div className="slideable-container" style={{ ...getContainerStyle(configuration?.conversationSummaryStyle), ...{ minHeight: "50px", width: "100%" } }}>
            <div style={contentContainerStyle}>
                <div style={{ height: "100%", width: "95%", marginTop: "12px", overflow: "hidden auto" }} >
                    {messageListState === States.loaded ? activeView : getStateView()}
                </div>

            </div>
        </div>
    );
};

export default AIConversationSummaryView;
