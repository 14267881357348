import { ButtonStyle, TabPaneContentViewStyle, TabsWrapperStyle, draggableStyle, getTabContentStyle, getTabItemStyle, getTabListStyle, tabItemWrapperStyle, tabPaneContentStyle } from "./style";
import { CometChatDraggable, CometChatIconButton } from "@cometchat/uikit-elements";
import { CometChatTabItem, IconButtonAlignment, TabAlignment } from "@cometchat/uikit-resources";
import React, { useCallback, useRef, useState } from "react";

import { TabItemStyle } from "@cometchat/uikit-shared";
import { TabsStyle } from "./TabsStyle";
import { createComponent } from "@lit-labs/react";

interface TabsProps {
    tabAlignment?: TabAlignment,
    tabsStyle?: TabsStyle,
    tabs: CometChatTabItem[],
    // isDraggable?: boolean,
    keepAlive?: boolean,
    tabIconAlignment?: IconButtonAlignment
}

const defaultProps: TabsProps = {
    tabAlignment: TabAlignment.bottom,
    tabsStyle: {},
    tabs: [],
    // isDraggable: false,
    keepAlive: false,
    tabIconAlignment: IconButtonAlignment.top
}

const CometChatIconButtonBubble = createComponent({
    tagName: 'cometchat-icon-button',
    elementClass: CometChatIconButton,
    react: React,
    events: {
        'ccButtonClicked': 'cc-button-clicked'
    }
});

// const CometChatDraggableComponent = createComponent({
//     tagName: "cometchat-draggable",
//     elementClass: CometChatDraggable,
//     react: React
// })

const CometChatTabs = (props: TabsProps) => {
    const {
        tabAlignment,
        tabIconAlignment,
        tabsStyle,
        tabs,
        // isDraggable,
        keepAlive
    } = { ...defaultProps, ...props };

    const [activeTab, setActiveTab] = useState<CometChatTabItem | null>(null);
    const [activeTabId, setActiveTabId] = useState<string>("");
    const childViewRef = useRef(null);

    const openViewOnCLick = useCallback((tabItem: CometChatTabItem) => {

        if (tabs && tabs.length > 0) {
            childViewRef.current = null;
            let index = tabs.findIndex(
                (item: CometChatTabItem) => item.id === tabItem.id
            );

            if (index > -1) {
                setActiveTab(tabItem);
                setActiveTabId(tabItem.id!);
                childViewRef.current = tabs[index].childView;
            }
        }
    }, [childViewRef, tabs])

    React.useEffect(() => {
        if (activeTabId) {
            const tabItem = tabs?.find((item: CometChatTabItem) => item.id === activeTabId);
            if (tabItem) {
                openViewOnCLick(tabItem);
            }
        } else {
            const tabItem = tabs?.find((item: CometChatTabItem) => item.isActive);
            if (tabItem) {
                openViewOnCLick(tabItem);
            }
        }

    }, [activeTabId, openViewOnCLick, tabs]);

    const getButtonStyle = useCallback((tab: CometChatTabItem) => {
        const { style, iconURL } = tab || {};
        const { id } = activeTab || {};
        const active = id === tab?.id;
        return ButtonStyle(style as TabItemStyle, active, iconURL);
    }, [activeTab]);

    const getTabItems = useCallback(() => {

        return tabs?.map((tab, index) => {

            const placement = index === 0 ? 'first' : index === (tabs.length - 1) ? 'last' : '';
            let key = `${tab.id}-${Math.floor((Math.random() * 10) + 1)}`;
            return (
                // <div className="cc-tab-item" style={getTabsPlacement(tab, placement)} key={tab.id}>
                <div className="cc-tab-item" style={getTabItemStyle(tabAlignment, tab, activeTab, placement, tabsStyle)} key={key}>
                    <CometChatIconButtonBubble
                        alignment={tabIconAlignment}
                        iconURL={tab.iconURL}
                        text={tab.title}
                        buttonStyle={getButtonStyle(tab)}
                        ccButtonClicked={(e) => openViewOnCLick(tab)}
                    />
                </div>
            )
        })
    }, [activeTab]);


    const getTabList = () => {

        const tabWrapperView = (
            <div className="cc-tab-item__wrapper" style={tabItemWrapperStyle()}>
                {getTabItems()}
            </div>
        );
        // return isDraggable ? (
        //     <CometChatDraggableComponent draggableStyle={draggableStyle()}>{tabWrapperView}</CometChatDraggableComponent>
        // ) : (tabWrapperView);
        return tabWrapperView;
    };

    const getTabPane = useCallback(() => {

        if (keepAlive) {

            return tabs.map((tab) => {

                return (
                    <div key={tab.id} className="cc-tab-pane-content" style={tabPaneContentStyle(tab, activeTab)}>
                        {tab?.childView ? <div className="cc-tab-pane-content-view" style={TabPaneContentViewStyle(tabsStyle)}>{tab?.childView}</div> : null}
                    </div>
                );

            });

        } else {
            return childViewRef?.current ? <div className="cc-tab-pane-content" style={TabPaneContentViewStyle(tabsStyle)}>{childViewRef?.current}</div> : null
        }
    }, [activeTab])

    return (
        <div className="cc-tabs" style={TabsWrapperStyle(tabsStyle)}>
            <div className="cc-tab-list" style={getTabListStyle(tabsStyle, tabAlignment as TabAlignment)}>
                {getTabList()}
            </div>
            <div className="cc-tab-pane" style={getTabContentStyle(tabsStyle)}>
                {getTabPane()}
            </div>
        </div>
    )
}

export { CometChatTabs };