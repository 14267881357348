import { CallLogParticipantsStyle } from "@cometchat/uikit-shared";
import { AvatarStyle, ListItemStyle } from "@cometchat/uikit-elements";
import { DatePatterns, States, localize } from "@cometchat/uikit-resources";
import backIcon from "./assets/backbutton.svg";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { useCallback, useContext, useMemo } from "react";
import { convertMinutesToHoursMinutesSeconds } from "../Utils/utils";
import { getAvatarStyle, getBackButtonStyle, getButtonContainerStyle, getCallDateStyle, getContainerStyle, getListItemStyle, getListStyle, getSubtitleStyle, getTitleStyle } from "./style";
import { CometChatList } from "../../Shared/Views/CometChatList";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import { CometChatButton } from "../../Shared/Views/CometChatButton";

interface ICallLogParticipantsProps {
    title?: string;
    backIconUrl?: string;
    call: any;
    datePattern?: DatePatterns;
    avatarStyle?: AvatarStyle;
    listItemStyle?: ListItemStyle;
    callLogParticipantsStyle?: CallLogParticipantsStyle;
    listItemView?: any;
    subtitleView?: any;
    tailView?: any;
    onBackClick?: Function;
    onItemClick?: Function;
}

const defaultProps: ICallLogParticipantsProps = {
    title: localize("PARTICIPANTS"),
    backIconUrl: backIcon,
    call: undefined,
    datePattern: DatePatterns.time,
    avatarStyle: {} as AvatarStyle,
    listItemStyle: {} as ListItemStyle,
    callLogParticipantsStyle: {} as CallLogParticipantsStyle,
    listItemView: null,
    subtitleView: null,
    tailView: null,
    onBackClick: undefined,
    onItemClick: undefined

}

const CometChatCallLogParticipants = (props: ICallLogParticipantsProps) => {
    const {
        title,
        backIconUrl,
        call,
        datePattern,
        avatarStyle,
        listItemStyle,
        callLogParticipantsStyle,
        listItemView,
        subtitleView,
        tailView,
        onBackClick,
        onItemClick
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);

    const handleItemClick = useCallback((item: any) => {
        if (onItemClick) {
            onItemClick(item);
        }
    }, [onItemClick])

    const getCallParticipants = useCallback(() => {
        return call?.getParticipants();
    }, [call])

    const getCallInitiatedAt = useCallback(() => {
        return call?.getInitiatedAt();
    }, [call])

    const getDurationOfCall = (item: any) => {
        if (item?.getHasJoined() || item?.getJoinedAt()) {
            return convertMinutesToHoursMinutesSeconds(item?.getTotalDurationInMinutes());
        } else {
            return convertMinutesToHoursMinutesSeconds(0);
        }
    }

    const getListItemSubtitleView = useCallback((item: any): JSX.Element => {
        if (subtitleView) {
            return subtitleView(item);
        } else {
            return (
                <>
                    <div style={getSubtitleStyle(theme, callLogParticipantsStyle!)}>
                        {getDurationOfCall(item)}
                    </div>
                </>
            );
        }
    }, [subtitleView, theme, callLogParticipantsStyle])

    const getListItemTailView = useCallback((item: any): JSX.Element => {
        if (tailView) {
            return tailView(item);
        } else {
            return (
                <>
                    {
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <cometchat-date dateStyle={JSON.stringify(getCallDateStyle(theme, callLogParticipantsStyle!))} pattern={datePattern} timestamp={getCallInitiatedAt()} />
                        </div>
                    }
                </>
            );
        }
    }, [tailView, theme, callLogParticipantsStyle, datePattern, getCallInitiatedAt])

    const getListItem = useMemo(() => {
        return function (item: any, index: number): any {
            if (listItemView) {
                return listItemView(item);
            } else {
                return (
                    <>
                        <CometChatListItem
                            title={item?.getName()}
                            avatarURL={item?.getAvatar()}
                            avatarName={item?.getName()}
                            listItemStyle={getListItemStyle(theme, listItemStyle)}
                            avatarStyle={getAvatarStyle(theme, avatarStyle)}
                            hideSeparator={true}
                            onClick={e => handleItemClick?.(item)}
                            subtitleView={getListItemSubtitleView(item)}
                            tailView={getListItemTailView(item)}
                        />
                    </>
                )
            }
        }
    }, [listItemView, theme, listItemStyle, avatarStyle, getListItemSubtitleView, getListItemTailView, handleItemClick])

    return (
        <>
            <div style={getContainerStyle(theme, callLogParticipantsStyle!)}>
                <div style={{ display: "flex", padding: "10px 20px", alignItems: "center", marginBottom: "10px" }}>
                    {onBackClick ?
                        <div style={getButtonContainerStyle()}>
                            <CometChatButton iconURL={backIconUrl} buttonStyle={getBackButtonStyle(theme, callLogParticipantsStyle!)} onClick={onBackClick?.()} />
                        </div> :
                        null
                    }
                    <div style={getTitleStyle(theme, callLogParticipantsStyle!)}>
                        {title}
                    </div>
                </div>
                <CometChatList
                    hideSearch={true}
                    list={getCallParticipants() || []}
                    listItem={getListItem}
                    listItemKey="getUid"
                    listStyle={getListStyle(theme, callLogParticipantsStyle!)}
                    state={States.loaded}
                    showSectionHeader={false}
                />
            </div>
        </>
    )

}

export { CometChatCallLogParticipants };