import "@cometchat/uikit-elements";

import { CometChatActionsIcon, CometChatActionsView, MessageBubbleAlignment, Placement } from "@cometchat/uikit-resources";
import { MessageBubbleAlignmentStyles, MessageBubbleAvatarStyles, MessageBubbleTitleStyles, MessageBubbleWrapperStyles, MessageOptionsStyles, menuListStyles } from "./style";
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { BaseStyle } from '@cometchat/uikit-shared';
import { CometChatContextMenu } from "../CometChatContextMenu";
import { CometChatThemeContext } from "../../../CometChatThemeContext";
import MoreIcon from "./assets/More.svg";

interface IMessageBubbleProps {
  id: any;
  setRef?: (ref: any) => void;
  leadingView: any;
  headerView: any;
  replyView: any;
  contentView: any;
  bottomView: any;
  threadView: any;
  footerView: any;
  statusInfoView?: any;
  options: (CometChatActionsIcon | CometChatActionsView)[];
  alignment: MessageBubbleAlignment;
  messageBubbleStyle: BaseStyle,
  moreIconURL?: string,
  topMenuSize?: number
};

const CometChatMessageBubble = (props: IMessageBubbleProps) => {
  const {
    id,
    leadingView = null,
    headerView = null,
    replyView = null,
    contentView = null,
    bottomView = null,
    threadView = null,
    footerView = null,
    statusInfoView = null,
    options = [],
    alignment = MessageBubbleAlignment.right,
    messageBubbleStyle = new BaseStyle({
      width: "100%",
      height: "auto",
      background: "",
      borderRadius: "12px",
      border: "none"
    }),
    moreIconURL = MoreIcon,
    topMenuSize = 5,
    setRef
  } = props;

  const contentStyle = {
    borderRadius: '8px',
  };

  const getMessageBubbleStyle = () => {
    const object = {
      ...messageBubbleStyle,
      ...contentStyle,
    };
    return object;
  };

  const getMessageBubbleStyleDimension = () => {
    const object = {
      height: messageBubbleStyle.height,
      width: messageBubbleStyle.width,
    };

    return object;
  };

  const { theme } = useContext(CometChatThemeContext);
  const messageRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageRef && messageRef.current && setRef) {
      setRef(messageRef);
    }
  }, [messageRef, setRef]);



  const [isHovering, setIsHovering] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
  let timeoutId: NodeJS.Timeout | null = null;

  const hideMessageOptions =
    () => {
      timeoutId = setTimeout(() => {
        setIsHovering(false);
      }, 150);
    }

  const showMessageOptions =
    () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setIsHovering(true);
    }

  const getLeadingView = () => {
    if (leadingView && alignment === MessageBubbleAlignment.left) {
      return (

        <div
          className='cc-message-bubble__avatar'
          style={MessageBubbleAvatarStyles()}
        >
          {leadingView}
        </div >
      )
    }
  }

  const getHeaderView = () => {
    if (headerView) {
      return (
        <div
          className='cc-message-bubble__header'
          style={MessageBubbleTitleStyles(alignment, MessageBubbleAlignment)}
        >
          {headerView}
        </div>
      )
    }
  }

  const onOptionClicked = (data: CometChatActionsIcon | CometChatActionsView) => {
    options.forEach((option) => {
      if (option instanceof CometChatActionsIcon) {
        if (option.id === data?.id && id) {
          option.onClick?.(parseInt(id));
        }
      }
    });
  }

  const getMessageOptions = () => {

    if (options && options.length > 0 && isHovering) {

      return (
        <div className='cc-message-options'
          style={MessageOptionsStyles(alignment, MessageBubbleAlignment, headerView, theme)}
        >
          <CometChatContextMenu
            moreIconURL={moreIconURL}
            topMenuSize={topMenuSize}
            ContextMenuStyle={menuListStyles(theme)}
            data={options}
            moreIconHoverText={''}
            onOptionClicked={onOptionClicked}
            placement={getPlacementAlignment()}
          />
        </div>
      )
    }
  }

  const getPlacementAlignment = () => {
    if (isMobile()) {
      return checkBubblePosition();
    }

    return props.alignment === MessageBubbleAlignment.left
      ? Placement.right
      : Placement.left;
  };

  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  const checkBubblePosition = () => {
    const bubble = messageRef.current;
    if (bubble) {
      const rect = bubble.getBoundingClientRect();
      const isAtTop = rect.top < window.innerHeight / 2;
      const isAtBottom = rect.bottom > window.innerHeight / 2;
      if (isAtTop) {
        return Placement.bottom;
      } else if (isAtBottom) {
        return Placement.top;
      } else {
        return Placement.bottom
      }
    } else {
      return Placement.bottom
    }
  };

  return (
    <div className='cc-message-bubble__wrapper'
      ref={messageRef}
      style={MessageBubbleWrapperStyles(alignment, MessageBubbleAlignment)}
      onMouseEnter={showMessageOptions}
      onMouseLeave={hideMessageOptions}
    >
      {getLeadingView()}
      <div className='cc-message-bubble'
        style={MessageBubbleAlignmentStyles(alignment, MessageBubbleAlignment)}
      >
        {getMessageOptions()}
        {getHeaderView()}
        <div
          className='cc-message-bubble__content'
          style={getMessageBubbleStyleDimension()}
        >
          <div style={getMessageBubbleStyle()}>
            {replyView ? replyView : null}
            {contentView ? contentView : null}
            {statusInfoView ? statusInfoView : null}
          </div>
          {bottomView ? bottomView : null}
          {footerView ? footerView : null}
          {threadView ? threadView : null}
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export { CometChatMessageBubble }
