import { AvatarStyle, ListItemStyle } from "@cometchat/uikit-elements";
import {
  CSSProperties,
  JSX,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import {
  CometChatOption,
  CometChatUIKitConstants,
  SelectionMode,
  States,
  TitleAlignment,
  localize,
  UserPresencePlacement,
} from "@cometchat/uikit-resources";
import {
  UsersWrapperStyle,
  avatarStyle,
  listItemStyle,
  listStyle,
  menuStyles,
  statusIndicatorStyle,
  tailViewSelectionContainerStyle,
} from "./style";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatCheckbox } from "../Shared/Views/CometChatCheckbox";
import { CometChatList } from "../Shared/Views/CometChatList";
import { CometChatListItem } from "../Shared/Views/CometChatListItem";
import { CometChatMenuList } from "../Shared/Views/CometChatMenuList";
import { CometChatRadioButton } from "../Shared/Views/CometChatRadioButton";
import { CometChatThemeContext } from "../CometChatThemeContext";
import { Hooks } from "./hooks";
import SearchIcon from "./assets/search.svg";
import SpinnerIcon from "./assets/spinner.svg";
import { UsersManager } from "./controller";
import { UsersStyle } from "@cometchat/uikit-shared";
import { useCometChatErrorHandler } from "../CometChatCustomHooks";
import { MessageUtils } from "../Shared/Utils/MessageUtils";
import React from "react";

export interface IUsersProps {
  /**
   * Title of the component
   *
   * @defaultValue `localize("USERS")`
   */
  title?: string;
  /**
   * Alignment of the `title` text
   *
   * @defaultValue `TitleAlignment.left`
   */
  tileAlignment?: TitleAlignment;
  /**
   * Hide the search bar
   *
   * @defaulValue `false`
   */
  hideSearch?: boolean;
  /**
   * Image URL for the search icon to use in the search bar
   *
   * @defaultValue `./assets/search.svg`
   */
  searchIconURL?: string;
  /**
   * Text to be displayed when the search input has no value
   *
   * @defaultValue `localize("SEARCH")`
   */
  searchPlaceholderText?: string;
  /**
   * Custom list item view to be rendered for each user in the fetched list
   */
  listItemView?: (user: CometChat.User) => JSX.Element;
  /**
   * Show alphabetical header
   *
   * @defaultValue `true`
   */
  showSectionHeader?: boolean;
  /**
   * Property on the user object
   *
   * @remarks
   * This property will be used to extract the section header character from the user object
   *
   * @defaultValue `getName`
   */
  sectionHeaderKey?: keyof CometChat.User;
  /**
   * Custom view for the loading state of the component
   */
  loadingStateView?: JSX.Element;
  /**
   * Image URL for the default loading view
   *
   * @defaultValue `./assets/spinner.svg`
   */
  loadingIconURL?: string;
  /**
   * Hide error view
   *
   * @remarks
   * If set to true, hides the default and the custom error view
   *
   * @defaultValue `false`
   */
  hideError?: boolean;
  /**
   * Custom view for the error state of the component
   */
  errorStateView?: JSX.Element;
  /**
   * Text to display in the default error view
   *
   * @defaultValue `localize("SOMETHING_WRONG")`
   */
  errorStateText?: string;
  /**
   * Custom view for the empty state of the component
   */
  emptyStateView?: JSX.Element;
  /**
   * Text to display in the default empty view
   *
   * @defaultValue `localize("NO_USERS_FOUND")`
   */
  emptyStateText?: string;
  /**
   * Custom subtitle view to be rendered for each user in the fetched list
   *
   * @remarks
   * This prop is used if `listItemView` prop is not provided
   */
  subtitleView?: (user: CometChat.User) => JSX.Element;
  /**
   * Hide user presence
   *
   * @remarks
   * If set to true, the status indicator of the default list item view is not displayed
   *
   * @defaultValue `false`
   */
  disableUsersPresence?: boolean;
  /**
   * Custom view to render on the top-right of the component
   */
  menus?: JSX.Element;
  /**
   * List of actions available on mouse over on the default list item component
   */
  options?: (user: CometChat.User) => CometChatOption[];
  /**
   * Hide the separator at the bottom of the default list item view
   *
   * @defaultValue `false`
   */
  hideSeparator?: boolean;
  /**
   * Selection mode to use for the default tail view
   *
   * @remarks
   * This prop is used if `listItemView` prop is not provided.
   *
   * @defaultValue `SelectionMode.none`
   */
  selectionMode?: SelectionMode;
  /**
   * Function to call when a user from the fetched list is selected
   *
   * @remarks
   * This prop is used if `selectionMode` prop is not `SelectionMode.none`
   */
  onSelect?: (users: CometChat.User, selected: boolean) => void;
  /**
   * Request builder to fetch users
   *
   * @remarks
   * If the search input is not empty and the `searchRequestBuilder` prop is not provided,
   * the search keyword of this request builder is set to the text in the search input
   *
   * @defaultValue Default request builder having the limit set to 30
   */
  usersRequestBuilder?: CometChat.UsersRequestBuilder;
  /**
   * Request builder with search parameters to fetch users
   *
   * @remarks
   * If the search input is not empty,
   * the search keyword of this request builder is set to the text in the search input
   */
  searchRequestBuilder?: CometChat.UsersRequestBuilder;
  /**
   * Function to call on click of the default list item view of a user
   */
  onItemClick?: (user: CometChat.User) => void;
  /**
   * Function to call whenever the component encounters an error
   */
  onError?: ((error: CometChat.CometChatException) => void) | null;
  /**
   * Styles to apply to the status indicator component of the default list item view
   */
  statusIndicatorStyle?: CSSProperties;
  /**
   * Styles to apply to the avatar component of the default list item view
   */
  avatarStyle?: AvatarStyle;
  /**
   * Styles to apply to this component
   */
  usersStyle?: UsersStyle;
  /**
   * Styles to apply to the default list item view
   */
  listItemStyle?: ListItemStyle;
  /**
   * User to highlight
   *
   * @remarks
   * This prop is used if `listItemView` prop is not provided
   */
  activeUser?: CometChat.User;

  /**
   * Search keyword to filter the list of users.
   *
   * @defaultValue `""`
   */
  searchKeyword?: string;
  /**
   * Callback function to be executed when the user list is empty.
   */
  onEmpty?: () => void;

  /**
   * Flag to indicate whether users are currently being fetched.
   *
   * @defaultValue `false`
   */
  fetchingUsers?: boolean;
  /**
   * Timeout reference for fetching users.
   */
  fetchTimeOut?: any;
  /**
   * Placement of user presence information within the user interface.
   * @defaultValue `bottom`
   */
  userPresencePlacement?: UserPresencePlacement;
  /**
   * Flag to indicate whether to disable loading state while fetching users.
   * @defaultValue `false`
   */
  disableLoadingState?: boolean;
  /**
   * URL of the icon to be used for the close button.
   */
  closeButtonIconURL?: string;
}

type State = {
  searchText: string;
  userList: CometChat.User[];
  fetchState: States;
  isFirstReload: boolean;
  fetchingUsers: boolean;
  fetchTimeOut: any;
  disableLoadingState: boolean;
};

export type Action =
  | { type: "setSearchText"; searchText: State["searchText"] }
  | {
    type: "appendUsers";
    users: CometChat.User[];
    removeOldUsers?: boolean;
    usersManager?: UsersManager | null;
    onEmpty?: () => void;
  }
  | { type: "setFetchState"; fetchState: States }
  | { type: "setUserList"; userList: CometChat.User[] }
  | { type: "updateUser"; user: CometChat.User }
  | { type: "setIsFirstReload"; isFirstReload: boolean };

function stateReducer(state: State, action: Action): State {
  let newState = state;
  const { type } = action;
  switch (type) {
    case "setSearchText":
      newState = { ...state, searchText: action.searchText };
      break;
    case "appendUsers":
      let users: CometChat.User[] = [];
      if (action.removeOldUsers) {
        if (!state.disableLoadingState) {
          state.userList = [];
        }
        users = action.users;
        if (!state.disableLoadingState) {
          newState = { ...state, userList: users };
        }
      } else {
        if (
          action.usersManager &&
          [0].includes(action.usersManager?.getCurrentPage()) &&
          !action.users.length
        ) {
          if (!action.users.length && action.onEmpty) {
            setTimeout(() => {
              action.onEmpty!();
            });
            newState = {
              ...state,
              fetchState: States.empty,
            };
          }
        } else if (action.users.length !== 0) {
          newState = {
            ...state,
            userList:
              action.usersManager?.getCurrentPage() == 1
                ? [...action.users]
                : [...state.userList, ...action.users],
          };
        }
      }
      break;
    case "setUserList":
      newState = { ...state, userList: action.userList };
      break;
    case "setFetchState":
      newState = { ...state, fetchState: action.fetchState };
      break;
    case "updateUser": {
      const { userList } = state;
      const { user: targetUser } = action;
      const targetUserUid = targetUser.getUid();
      const targetIdx = userList.findIndex(
        (user) => user.getUid() === targetUserUid
      );
      if (targetIdx > -1) {
        newState = {
          ...state,
          userList: userList.map((user, i) => {
            return i === targetIdx ? targetUser : user;
          }),
        };
      }
      break;
    }
    case "setIsFirstReload":
      newState = { ...state, isFirstReload: action.isFirstReload };
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const x: never = type;
    }
  }
  return newState;
}

/**
 * Renders a scrollable list of users that has been created in a CometChat app
 */
export function CometChatUsers(props: IUsersProps) {
  const {
    title = localize("USERS"),
    tileAlignment = TitleAlignment.left,
    hideSearch = false,
    searchIconURL = SearchIcon,
    searchPlaceholderText = localize("SEARCH"),
    listItemView = null,
    showSectionHeader = true,
    sectionHeaderKey = "getName",
    loadingStateView, // Will use the default provided by CometChatList if undefined
    loadingIconURL = SpinnerIcon,
    hideError = false,
    errorStateView, // Will use the default provided by CometChatList if undefined
    errorStateText = localize("SOMETHING_WRONG"),
    emptyStateView, // Will use the default provided by CometChatList if undefined
    emptyStateText = localize("NO_USERS_FOUND"),
    subtitleView = null,
    disableUsersPresence = false,
    menus = null,
    options = null,
    hideSeparator = false,
    selectionMode = SelectionMode.none,
    onSelect, // Won't use if undefined
    usersRequestBuilder = null,
    searchRequestBuilder = null,
    onItemClick, // Won't use if undefined
    onError,
    statusIndicatorStyle: statusIndicatorStyleObject = null,
    avatarStyle: avatarStyleObject = null,
    usersStyle: usersStyleObject = null,
    listItemStyle: listItemStyleObject = null,
    activeUser = null,
    searchKeyword = "",
    onEmpty,
    fetchingUsers = false,
    fetchTimeOut,
    userPresencePlacement = UserPresencePlacement.bottom,
    disableLoadingState = false,
  } = props;

  const [state, dispatch] = useReducer(stateReducer, {
    searchText: "",
    userList: [],
    fetchState: States.loading,
    isFirstReload: false,
    fetchingUsers,
    fetchTimeOut,
    disableLoadingState: disableLoadingState,
  });
  const errorHandler = useCometChatErrorHandler(onError);
  const usersManagerRef = useRef<UsersManager | null>(null);
  const fetchNextIdRef = useRef("");
  const { theme } = useContext(CometChatThemeContext);
  const attachListenerOnFetch = useRef<boolean>(false);
  const isConnectionReestablished = useRef<boolean>(false);
  const usersSearchText = useRef<string>("");
  let isJustMounted = useRef<boolean>(true);
  const selectedUsers = useRef<{ [uid: string]: CometChat.User }>({});
  const [userChecked, setUserChecked] = React.useState<string | null>(null);
  (() => {
    if (state.searchText && state.searchText !== usersSearchText.current) {
      usersSearchText.current = state.searchText;
    }
    if (state.isFirstReload) {
      attachListenerOnFetch.current = true;
      state.isFirstReload = false;
    }
  })();

  /**
   * Initiates a fetch request and appends the fetched users to the `userList` state
   *
   * @remarks
   * This function also updates the `fetchState` state
   *
   * @param fetchId - Fetch Id to decide if the fetched data should be appended to the `userList` state
   */
  const fetchNextAndAppendUsers = useCallback(
    async (fetchId: string): Promise<void> => {
      const usersManager = usersManagerRef.current;
      if (!usersManager) {
        return;
      }
      let initialState =
        isConnectionReestablished.current ||
          (disableLoadingState && !isJustMounted)
          ? States.loaded
          : States.loading;
      dispatch({ type: "setFetchState", fetchState: initialState });
      try {
        const newUsers = await usersManager.fetchNext();
        if (fetchId !== fetchNextIdRef.current) {
          return;
        }
        let removeOldUsers = isConnectionReestablished.current ? true : false;
        dispatch({
          type: "appendUsers",
          users: newUsers,
          removeOldUsers,
          usersManager,
          onEmpty,
        });
        if (attachListenerOnFetch.current) {
          UsersManager.attachConnestionListener(() => {
            const requestBuilder =
              usersRequestBuilder === null
                ? new CometChat.UsersRequestBuilder().setLimit(30)
                : usersRequestBuilder;
            usersManagerRef.current = new UsersManager({
              searchText: usersSearchText.current,
              usersRequestBuilder: requestBuilder,
              searchRequestBuilder,
              usersSearchText
            });
            isConnectionReestablished.current = true;
          });
          attachListenerOnFetch.current = false;
        }
        if (!isConnectionReestablished.current) {
          dispatch({ type: "setFetchState", fetchState: States.loaded });
        } else {
          isConnectionReestablished.current = false;
        }
      } catch (error: unknown) {
        if (fetchId === fetchNextIdRef.current && state.userList?.length <= 0) {
          dispatch({ type: "setFetchState", fetchState: States.error });
        }
        errorHandler(error);
      }
      isJustMounted.current = false;
    },
    [errorHandler, dispatch]
  );

  /**
   * Updates the `searchText` state
   */
  const onSearch = useCallback(
    (newSearchText: string): void => {
      const trimmedText = newSearchText.trim();
      if (
        newSearchText.length === 0 ||
        (trimmedText.length === newSearchText.length && trimmedText.length > 0)
      ) {
        usersSearchText.current = "";
        dispatch({ type: "setSearchText", searchText: newSearchText });
      }
      // dispatch({type: "setSearchText", searchText: newSearchText});
    },
    [dispatch]
  );

  /**
   * Update the user object if found in the `userList` state
   */
  const updateUser = useCallback(
    (user: CometChat.User): void => {
      dispatch({ type: "updateUser", user });
    },
    [dispatch]
  );

  /**
   * Creates menus to display
   */
  function getMenus(): JSX.Element | null {
    if (!menus) {
      return null;
    }
    return (
      <div className='cc-users__menus' style={menuStyles()}>
        {menus}
      </div>
    );
  }

  /**
 * Checks if the given user is currently selected based on their UID.
 */
  const isUserSelected = (user: CometChat.User) => {
    return user.getUid() === userChecked || !!selectedUsers.current[user.getUid()];
  };

  const addMembersToList = (user: CometChat.User, event: any) => {
    let selected = event?.detail?.checked;
    if (selectionMode === SelectionMode.single) {
      setUserChecked(user.getUid());
    }
    if (onSelect) {
      onSelect(user, selected);
    }
    if (selected) {
      selectedUsers.current[user.getUid()] = user;
    } else {
      delete selectedUsers.current[user.getUid()];
    }    
  };

  /**
   * Creates tail view for the default list item view
   */
  function getDefaultListItemTailView(
    user: CometChat.User
  ): JSX.Element | null {
    if (
      selectionMode !== SelectionMode.single &&
      selectionMode !== SelectionMode.multiple
    ) {
      return null;
    }
    let tailViewContent: JSX.Element;
    if (selectionMode === SelectionMode.single) {
      tailViewContent = (
        <CometChatRadioButton onChange={(e) => addMembersToList(user, e)} checked={isUserSelected(user)} />
      );
    } else {
      tailViewContent = (
        <CometChatCheckbox onChange={(e) => addMembersToList(user, e)} checked={isUserSelected(user)} />
      );
    }
    return (
      <div style={tailViewSelectionContainerStyle()}>{tailViewContent}</div>
    );
  }

  /**
   * Creates menu view for the default list item view
   *
   * @remarks
   * This menu view is shown on mouse over the default list item view.
   * The visibility of this view is handled by the default list item view
   */
  function getDefaultListItemMenuView(
    user: CometChat.User
  ): JSX.Element | null {
    let curOptions: CometChatOption[] | undefined;
    if (!(curOptions = options?.(user))?.length) {
      return null;
    }
    return (
      <CometChatMenuList
        data={curOptions}
        onOptionClick={(e) => e.detail.data.onClick?.()}
      />
    );
  }

  /**
   * Get the status indicator color to use for the default list item view
   *
   * @remarks
   * If the intention is not to show the status indicator, `null` should be returned
   */
  function getStatusIndicatorColor(user: CometChat.User): string | null {
    let userBlockedFlag = new MessageUtils().getUserStatusVisible(user);
    if (disableUsersPresence || userBlockedFlag) {
      return null;
    }
    return (
      usersStyleObject?.onlineStatusColor || theme.palette.getSuccess() || null
    );
  }

  const getStatusIndicatorStyle = (user: CometChat.User): CSSProperties | null => {
  let userBlockedFlag = new MessageUtils().getUserStatusVisible(user);

  if (disableUsersPresence || userBlockedFlag) {
    return null;
  }else if (!disableUsersPresence && !userBlockedFlag) {
    return (
      statusIndicatorStyle(
        statusIndicatorStyleObject
      )
    );
  }else {
    return null;
  }
}

  /**
   * Creates `listItem` prop of the `CometChatList` component
   */
  function getListItem(): (user: CometChat.User) => JSX.Element {
    if (listItemView) {
      return listItemView;
    }
    return function (user: CometChat.User): JSX.Element {
      return (
        <CometChatListItem
          id={user.getUid()}
          avatarURL={user.getAvatar()}
          avatarName={user.getName()}
          title={user.getName()}
          isActive={
            selectionMode === SelectionMode.none &&
            user.getUid() === activeUser?.getUid()
          }
          hideSeparator={hideSeparator}
          statusIndicatorColor={getStatusIndicatorColor(user)}
          statusIndicatorStyle={getStatusIndicatorStyle(user)}
          avatarStyle={avatarStyle(avatarStyleObject, theme)}
          listItemStyle={listItemStyle(
            listItemStyleObject,
            usersStyleObject,
            theme
          )}
          subtitleView={subtitleView?.(user)}
          subtitleViewClassName='cc-users__subtitle-view'
          tailView={getDefaultListItemTailView(user)}
          tailViewClassName='cc-users__tail-view'
          menuView={getDefaultListItemMenuView(user)}
          menuViewClassName='cc-users__options-view'
          onClick={(e) => onItemClick?.(user)}
          userPresencePlacement={userPresencePlacement}
        />
      );
    };
  }

  Hooks({
    usersManagerRef,
    fetchNextAndAppendUsers,
    searchText: state.searchText,
    usersRequestBuilder,
    searchRequestBuilder,
    dispatch,
    updateUser,
    fetchNextIdRef,
    searchKeyword,
    disableLoadingState,
    usersSearchText
  });
  return (
    <div
      className='cc-users'
      style={UsersWrapperStyle(usersStyleObject, theme)}
    >
      {getMenus()}
      <CometChatList
        title={title}
        titleAlignment={tileAlignment}
        hideSearch={state.fetchState === States.error || hideSearch}
        searchIconURL={searchIconURL}
        searchPlaceholderText={searchPlaceholderText}
        searchText={state.searchText}
        onSearch={onSearch}
        list={state.userList}
        listItem={getListItem()}
        onScrolledToBottom={() =>
          fetchNextAndAppendUsers(
            (fetchNextIdRef.current =
              "onScrolledToBottom_" + String(Date.now()))
          )
        }
        showSectionHeader={showSectionHeader}
        sectionHeaderKey={sectionHeaderKey}
        listItemKey='getUid'
        state={
          state.fetchState === States.loaded &&
            state.userList.length === 0 &&
            !onEmpty
            ? States.empty
            : state.fetchState
        }
        loadingView={loadingStateView}
        loadingIconURL={loadingIconURL}
        hideError={hideError}
        errorStateView={errorStateView}
        errorStateText={errorStateText}
        emptyStateView={emptyStateView}
        emptyStateText={emptyStateText}
        listStyle={listStyle(usersStyleObject, theme)}
      />
    </div>
  );
}
