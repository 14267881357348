import { CallLogHistoryStyle, CometChatUIKitCalls } from "@cometchat/uikit-shared";
import { DatePatterns, States, localize } from "@cometchat/uikit-resources";
import { convertMinutesToHoursMinutesSeconds, getCallStatusWithType } from "../Utils/utils";
import { getBackButtonStyle, getButtonContainerStyle, getCallDurationStyle, getCallStatusStyle, getContainerStyle, getDateSeparator, getDividerStyle, getListItemStyle, getListStyle, getSubtitleStyle, getTitleStyle } from "./style";
import { useCallback, useContext, useMemo, useRef, useState } from "react";

import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../../Shared/Views/CometChatButton";
import { CometChatList } from "../../Shared/Views/CometChatList";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { Hooks } from "./hooks";
import { ListItemStyle } from "@cometchat/uikit-elements";
import LoadingIcon from "./assets/LoadingIcon.svg";
import backIcon from "./assets/backbutton.svg";

interface ICallLogHistoryProps {
    title?: string;
    emptyStateText?: string;
    errorStateText?: string;
    backIconUrl?: string;
    loadingIconURL?: string;
    emptyStateView?: any;
    loadingStateView?: any;
    errorStateView?: any;
    subtitleView?: any;
    tailView?: any;
    callLogRequestBuilder?: any;
    callUser?: any;
    callGroup?: any;
    onItemClick?: Function;
    onBackClick?: Function;
    onError?: Function;
    datePattern?: DatePatterns;
    dateSeparatorPattern?: DatePatterns;
    listItemStyle?: ListItemStyle;
    callLogHistoryStyle?: CallLogHistoryStyle;
}

const defaultProps: ICallLogHistoryProps = {
    title: localize("CALL_HISTORY"),
    emptyStateText: localize("NO_CALLS_FOUND"),
    errorStateText: localize("SOMETHING_WRONG"),
    backIconUrl: backIcon,
    loadingIconURL: LoadingIcon,
    emptyStateView: null,
    loadingStateView: null,
    errorStateView: null,
    subtitleView: null,
    tailView: null,
    callLogRequestBuilder: undefined,
    callUser: undefined,
    callGroup: undefined,
    onItemClick: undefined,
    onBackClick: undefined,
    onError: () => { },
    datePattern: DatePatterns.time,
    dateSeparatorPattern: DatePatterns.DayDate,
    listItemStyle: {} as ListItemStyle,
    callLogHistoryStyle: {} as CallLogHistoryStyle,
}

const CometChatCallLogHistory = (props: ICallLogHistoryProps) => {
    const {
        title,
        emptyStateText,
        errorStateText,
        backIconUrl,
        loadingIconURL,
        emptyStateView,
        errorStateView,
        loadingStateView,
        subtitleView,
        tailView,
        callLogRequestBuilder,
        callUser,
        callGroup,
        onItemClick,
        onBackClick,
        onError,
        datePattern,
        dateSeparatorPattern,
        listItemStyle,
        callLogHistoryStyle
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);
    const [callList, setCallList] = useState<any[]>([]);
    const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);
    const [callListState, setCallListState] = useState(States.loading);
    const requestBuilder = useRef<any>(null);

    const onErrorCallback = useCallback(
        (error: any) => {
            if (!(error instanceof CometChat.CometChatException)) {
                let errorModel = {
                    code: error?.code,
                    name: error?.name,
                    message: error?.message,
                    details: error?.details,
                };
                let errorObj = new CometChat.CometChatException(errorModel);
                onError?.(errorObj);
            } else {
                onError?.(error);
            }
        },
        [onError]
    );

    const setCallLogRequestBuilder = useCallback((): any => {
        try {
            let builder;
            if (callLogRequestBuilder) {
                builder = callLogRequestBuilder;
            } else {
                const authToken = loggedInUser!.getAuthToken() || "";
                builder = new CometChatUIKitCalls.CallLogRequestBuilder()
                    .setLimit(30)
                    .setCallCategory("call")
                    .setAuthToken(authToken);
            }

            if (callGroup) {
                builder = builder.setGuid(callGroup?.getGuid());
            } else if (callUser) {
                builder = builder.setUid(callUser?.getUid());
            }

            return builder.build();
        } catch (e) {
            onErrorCallback(e);
        }
    }, [callLogRequestBuilder, callGroup, callUser, loggedInUser, onErrorCallback]);

    const fetchNextCallList = useCallback(async (): Promise<any[]> => {
        try {
            const calls = await requestBuilder?.current?.fetchNext();
            return calls;
        } catch (e) {
            onErrorCallback(e);
            throw new Error("Error while fetching call list");
        }
    }, [requestBuilder, onErrorCallback]);

    const getCallList = useCallback(async () => {
        try {
            const calls = await fetchNextCallList();
            if (calls && calls.length > 0) {
                setCallList((prevCallList) => {
                    return [...prevCallList, ...calls]
                })
                setCallListState(States.loaded);
            } else if (callList.length === 0) {
                setCallListState(States.empty);
            }
        } catch (e) {
            if (callList.length === 0) {
                setCallListState(States.error);
            }
            onErrorCallback(e);
        }
    }, [fetchNextCallList, setCallList, setCallListState, callList, onErrorCallback])

    const handleItemClick = useCallback((call: any) => {
        try {
            if (onItemClick) onItemClick(call);
        } catch (e) {
            onErrorCallback(e);
        }
    }, [onItemClick, onErrorCallback])

    const isDateDifferent = useCallback((firstDate: number, secondDate: number) => {
        try {
            let firstDateObj: Date, secondDateObj: Date;
            firstDateObj = new Date(firstDate * 1000);
            secondDateObj = new Date(secondDate * 1000);
            return (
                firstDateObj.getDate() !== secondDateObj.getDate() ||
                firstDateObj.getMonth() !== secondDateObj.getMonth() ||
                firstDateObj.getFullYear() !== secondDateObj.getFullYear()
            );
        } catch (e) {
            onErrorCallback(e);
            return false
        }
    }, [onErrorCallback])

    const getMessageBubbleDate = useCallback((item: any, i: number) => {
        try {
            if (i === 0) {
                return (
                    <div style={{ margin: "4px 0 0 8px" }}>
                        <cometchat-date timestamp={item.getInitiatedAt()} pattern={dateSeparatorPattern} dateStyle={JSON.stringify(getDateSeparator(theme, callLogHistoryStyle!))}></cometchat-date>
                    </div>
                );
            } else {
                if (
                    isDateDifferent(callList[i - 1]?.getInitiatedAt(), item.getInitiatedAt())
                ) {
                    return (
                        <>
                            <cometchat-divider dividerStyle={JSON.stringify(getDividerStyle(theme, callLogHistoryStyle!))} />
                            <div style={{ margin: "4px 0 0 8px" }}>
                                <cometchat-date timestamp={item.getInitiatedAt()} pattern={dateSeparatorPattern} dateStyle={JSON.stringify(getDateSeparator(theme, callLogHistoryStyle!))}></cometchat-date>
                            </div>
                        </>
                    );
                } else {
                    return null;
                }
            }
        } catch (e) {
            onErrorCallback(e);
            return null;
        }
    }, [dateSeparatorPattern, theme, callLogHistoryStyle, isDateDifferent, callList, onErrorCallback])

    const getCallDuration = useCallback((item: any) => {
        try {
            if (item?.getTotalDurationInMinutes()) {
                return convertMinutesToHoursMinutesSeconds(item?.getTotalDurationInMinutes());
            } else {
                return false;
            }
        } catch (e) {
            onErrorCallback(e);
            return false;
        }
    }, [onErrorCallback]);

    const getListItemSubtitleView = useCallback((item: any): JSX.Element => {
        if (subtitleView) {
            return subtitleView(item);
        } else {
            return (
                <>
                    <div style={{ display: 'flex' }}>
                        <cometchat-date
                            dateStyle={JSON.stringify(getSubtitleStyle(theme, callLogHistoryStyle!))}
                            pattern={datePattern}
                            timestamp={item?.getInitiatedAt()}
                        ></cometchat-date>
                        <div style={getCallStatusStyle(theme, callLogHistoryStyle!)}>{getCallStatusWithType(item, loggedInUser!, true)}</div>
                    </div>
                </>
            );
        }
    }, [subtitleView, theme, callLogHistoryStyle, datePattern, loggedInUser])

    const getListItemTailView = useCallback((item: any): JSX.Element => {
        if (tailView) {
            return tailView(item);
        } else {
            return (
                <>
                    <div style={getCallDurationStyle(theme, callLogHistoryStyle!)}>
                        {getCallDuration(item) ? getCallDuration(item) : '00:00'}
                    </div>
                </>
            );
        }
    }, [tailView, theme, callLogHistoryStyle, getCallDuration])

    const getListItem = useMemo(() => {
        return function (item: any, index: number): any {
            return (
                <>
                    {getMessageBubbleDate(item, index) ? <div>{getMessageBubbleDate(item, index)}</div> : null}
                    <CometChatListItem
                        loadingIconURL={loadingIconURL}
                        listItemStyle={getListItemStyle(theme, listItemStyle)}
                        onClick={e => handleItemClick?.(item)}
                        subtitleView={getListItemSubtitleView(item)}
                        tailView={getListItemTailView(item)}
                        hideSeparator={true}
                    />
                </>
            )
        };
    }, [getMessageBubbleDate, loadingIconURL, theme, listItemStyle, getListItemSubtitleView, getListItemTailView, handleItemClick]);

    Hooks(loggedInUser, setLoggedInUser, requestBuilder, setCallLogRequestBuilder, getCallList, onErrorCallback);

    return (
        <>
            <div style={getContainerStyle(theme, callLogHistoryStyle!)}>
                <div style={{ display: "flex", padding: "10px 30px", alignItems: "center", marginBottom: "10px" }}>
                    {
                        onBackClick ?
                            <div style={getButtonContainerStyle()}>
                                <CometChatButton iconURL={backIconUrl} buttonStyle={getBackButtonStyle(theme, callLogHistoryStyle!)} onClick={onBackClick?.()} />
                            </div> :
                            null
                    }
                    <div style={getTitleStyle(theme, callLogHistoryStyle!)}>
                        {title}
                    </div>
                </div>

                <CometChatList
                    hideSearch={true}
                    list={callList}
                    onScrolledToBottom={getCallList}
                    listItemKey="getSessionID"
                    listItem={getListItem}
                    loadingIconURL={loadingIconURL}
                    emptyStateText={emptyStateText}
                    errorStateText={errorStateText}
                    loadingView={loadingStateView}
                    emptyStateView={emptyStateView}
                    errorStateView={errorStateView}
                    listStyle={getListStyle(theme, callLogHistoryStyle!)}
                    state={callListState}
                    showSectionHeader={false}
                />
            </div>
        </>
    )
}

export { CometChatCallLogHistory };