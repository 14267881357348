import { AIAssistBotConfiguration, CometChatUIKitUtility } from '@cometchat/uikit-shared';
import { CometChatUIKitConstants, States, localize } from '@cometchat/uikit-resources';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getAvatarStyle, getBotBackgroundStyle, getBotChatContainerStyle, getBotChatHeaderStyle, getBotHeaderSubtitleStyle, getBotHeaderTitleStyle, getCloseButtonStyle, getMessageBubbleContainerStyle, getMessageBubbleStyle, getMessageInputStyle, getMessageListFooterStyle, getSendButtonStyle } from './style';
import { getBubbleAlignment, getBubbleFooterView, getContentView } from './utils';

import Close2xIcon from '../assets/close2x.svg'
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { CometChatList } from '../../Shared/Views/CometChatList';
import { CometChatMessageBubble } from '../../Shared/Views/CometChatMessageBubble';
import { CometChatTextInput } from '@cometchat/uikit-elements';
import { CometChatThemeContext } from '../../CometChatThemeContext';
import SendIcon from "../assets/send.svg";
import { createComponent } from "@lit-labs/react";

interface IAIAssistBotProps {
    configuration?: AIAssistBotConfiguration
    bot: CometChat.User | undefined
    sender: CometChat.User | undefined
    messageSendCallBack?: (message: string, bot: CometChat.User) => Promise<string>
    closeCallback?: () => void
};

const defaultProps: IAIAssistBotProps = {
    bot: undefined,
    sender: undefined,
    messageSendCallBack: undefined,
    closeCallback: undefined,
    configuration: undefined
}

const MessageInput = createComponent({
    tagName: 'cometchat-text-input',
    elementClass: CometChatTextInput,
    react: React,
    events: {
        'ccTextInputChanged': 'cc-text-input-changed',
        'ccTextInputEntered': 'cc-text-input-entered'
    }
})

const AIAssistBotChatView = (props: IAIAssistBotProps) => {
    let {
        bot,
        sender,
        messageSendCallBack,
        closeCallback,
        configuration
    } = { ...defaultProps, ...props };
    const scrollBottom = useRef(true);
    const subtitleText = localize("COMETCHAT_ASK_BOT_SUBTITLE");
    const botFirstMessage = useRef(localize("COMETCHAT_BOT_FIRST_MESSAGE"));
    const messageInputRef = useRef<JSX.IntrinsicElements["cometchat-text-input"] | null>(null);
    const inputTextRef = useRef("");
    const [messagesList, setMessagesList] = useState<any[]>([]);
    const { theme } = useContext(CometChatThemeContext);
    sender = sender!;
    bot = bot!;

    useEffect(
        () => {
            insertFirstMessage();
        }, []
    );

    const insertFirstMessage = useCallback(
        () => {
            if (configuration?.botFirstMessageText) {
                botFirstMessage.current = configuration.botFirstMessageText(bot!);
            }
            const message = new CometChat.TextMessage(sender!.getUid(), botFirstMessage.current, CometChatUIKitConstants.MessageReceiverType.user);
            message.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
            message.setMuid(CometChatUIKitUtility.ID());
            message.setSender(bot!);
            setMessagesList([message]);
        }, [messagesList, setMessagesList]
    )

    const onComposerEditTextChange = useCallback(
        (value: string) => {
            inputTextRef.current = value;
        }, [inputTextRef]
    )

    const getBotResponse = useCallback(
        (id: any) => {
            if (messageSendCallBack) {
                messageSendCallBack(inputTextRef.current, bot!).then(
                    (response) => {
                        const newMessage = new CometChat.TextMessage(
                            sender!.getUid(),
                            response,
                            CometChatUIKitConstants.MessageReceiverType.user
                        );
                        newMessage.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
                        newMessage.setMuid(CometChatUIKitUtility.ID());
                        newMessage.setSender(bot!);
                        newMessage.setStatus("sent");

                        setMessagesList(prevMessages => {
                            const messages = prevMessages.map((message) => {
                                if (message.getMuid() === id) {
                                    message.setStatus('sent');
                                }
                                return message;
                            })
                            messages.push(newMessage);
                            return messages;
                        });
                    }
                )
                    .catch(
                        (error) => {
                            setMessagesList(
                                (prevMessages) => {
                                    const messages = prevMessages.map(
                                        (message) => {
                                            if (message.getMuid() === id) {
                                                message.setStatus("error");
                                            }
                                            return message;
                                        }
                                    );
                                    return messages;
                                }
                            );
                        }
                    );
            }
        }, [messagesList, setMessagesList, inputTextRef]
    );

    const onMessageSendClick = useCallback(
        () => {
            if (!inputTextRef.current) {
                return;
            }
            const id = CometChatUIKitUtility.ID();
            const newMessage = new CometChat.TextMessage(
                bot!.getUid(),
                inputTextRef.current,
                CometChatUIKitConstants.MessageReceiverType.user
            );
            newMessage.setSentAt(CometChatUIKitUtility.getUnixTimestamp());
            newMessage.setMuid(id);
            newMessage.setSender(sender!);
            newMessage.setStatus("wait");
            setMessagesList(prevMessages => ([...prevMessages, newMessage]));
            messageInputRef?.current?.emptyInputField();
            getBotResponse(id)
        }, [messagesList, setMessagesList, getBotResponse, inputTextRef]
    );

    const triggerClose = () => {
        if (closeCallback) {
            closeCallback();
        }
    }

    const getListItem = useMemo(() => {
        return function (message: any, index: number): any {
            return (
                <div style={getMessageBubbleContainerStyle(message, sender!)}>
                    <CometChatMessageBubble
                        id={message?.id}
                        messageBubbleStyle={getMessageBubbleStyle(message, theme, sender!, configuration!)}
                        alignment={getBubbleAlignment(message, sender!)}
                        contentView={getContentView(message, theme, getBubbleAlignment(message, sender!), configuration!)}
                        footerView={getBubbleFooterView(message, configuration!, theme)}
                        leadingView={undefined}
                        headerView={undefined}
                        replyView={undefined}
                        bottomView={undefined}
                        threadView={undefined}
                        statusInfoView={undefined}
                        options={[]}
                    />
                </div>
            )
        };
    }, [getMessageBubbleStyle, getBubbleAlignment, getContentView, getBubbleFooterView]);

    return (
        <div style={getBotBackgroundStyle(theme)}>
            <div style={getBotChatContainerStyle(configuration?.assistBotStyle!, theme)}>
                <div style={getBotChatHeaderStyle(theme)}>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <cometchat-avatar name={bot?.getName()} avatarStyle={JSON.stringify(getAvatarStyle(configuration?.avatarStyle!))} image={bot?.getAvatar()}></cometchat-avatar>
                        <div>
                            <div style={getBotHeaderTitleStyle(configuration?.assistBotStyle!, theme)}>
                                {bot?.getName()}
                            </div>
                            <div style={getBotHeaderSubtitleStyle(configuration?.assistBotStyle!, theme)}>
                                {subtitleText}
                            </div>
                        </div>
                    </div>
                    <div>
                        <cometchat-button
                            iconURL={configuration?.closeIconURL || Close2xIcon}
                            onClick={triggerClose}
                            buttonStyle={JSON.stringify(getCloseButtonStyle(configuration?.assistBotStyle!, theme))}
                        />
                    </div>
                </div>
                <CometChatList
                    listStyle={{ height: 'calc(100% - 90px)' }}
                    list={messagesList}
                    listItem={getListItem}
                    listItemKey="getMuid"
                    state={States.loaded}
                    hideSearch={true}
                    showSectionHeader={false}
                    title={""}
                    scrollToBottom={scrollBottom?.current}
                />
                <div style={getMessageListFooterStyle()}>
                    <MessageInput
                        style={{ flex: 0.99 }}
                        ref={messageInputRef}
                        textInputStyle={getMessageInputStyle(configuration?.textInputStyle!, theme)}
                        ccTextInputChanged={(e: any) => onComposerEditTextChange(e?.detail?.value)}
                        ccTextInputEntered={() => onMessageSendClick()}
                    />
                    <cometchat-button
                        iconURL={configuration?.sendIconURL || SendIcon}
                        onClick={() => onMessageSendClick()}
                        buttonStyle={JSON.stringify(getSendButtonStyle(configuration?.assistBotStyle!, theme))}
                    />
                </div>
            </div>
        </div>
    )
};

export default AIAssistBotChatView;
