import { CallLogDetailsConfiguration, CallLogsConfiguration, WithDetailsStyle } from '@cometchat/uikit-shared';
import { getContainerStyle, getEmptyContainerStyle, getLabelStyle } from "./style";
import { useCallback, useContext, useState } from "react";

import { CometChatCallLogDetails } from "../CometChatCallLogDetails";
import { CometChatCallLogs } from "../CometChatCallLogs";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { Hooks } from "./hooks";
import { localize } from "@cometchat/uikit-resources";

interface ICallLogWithDetailsProps {
    isMobileView?: boolean;
    messageText?: string;
    withDetailsStyle?: WithDetailsStyle;
    callLogDetailsConfiguration?: CallLogDetailsConfiguration;
    callLogsConfiguration?: CallLogsConfiguration
}

const defaultProps: ICallLogWithDetailsProps = {
    isMobileView: false,
    messageText: localize("NO_CALLS_SELECTED"),
    withDetailsStyle: {} as WithDetailsStyle,
    callLogDetailsConfiguration: {} as CallLogDetailsConfiguration,
    callLogsConfiguration: {} as CallLogsConfiguration
};

const CometChatCallLogsWithDetails = (props: ICallLogWithDetailsProps) => {
    const {
        isMobileView,
        messageText,
        withDetailsStyle,
        callLogDetailsConfiguration,
        callLogsConfiguration
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);
    const [activeCall, setActiveCall] = useState(null);

    const handleInfoClick = useCallback((call: any) => {
        setActiveCall(call);
    }, [setActiveCall])

    const getCallLogStyle = useCallback(() => {
        if (isMobileView) {
            return {
                width: "100%",
                display: !activeCall ? "block" : "none",
            }
        } else {
            return {
                width: "280px",
                minWidth: "max(25%, 280px)",
            }
        }
    }, [isMobileView, activeCall])

    const getCallDetailStyle = useCallback(() => {
        if (isMobileView) {
            return {
                width: "100%",
                display: activeCall ? "block" : "none"
            }
        } else {
            return {
                width: "calc(100% - 280px)",
            }
        }
    }, [isMobileView, activeCall])

    const getBackClick = useCallback(() => {
        setActiveCall(null);
    }, [setActiveCall])

    Hooks(callLogDetailsConfiguration!, callLogsConfiguration!, setActiveCall, isMobileView!, theme);

    return (
        <div style={getContainerStyle(theme, withDetailsStyle!)}>
            <div style={getCallLogStyle()}>
                <CometChatCallLogs
                    title={localize("CALLS")}
                    onInfoClick={callLogsConfiguration?.onInfoClick || handleInfoClick}
                    activeCall={activeCall}
                    titleAlignment={callLogsConfiguration?.titleAlignment}
                    listItemView={callLogsConfiguration?.listItemView}
                    subtitleView={callLogsConfiguration?.subtitleView}
                    tailView={callLogsConfiguration?.tailView}
                    emptyStateView={callLogsConfiguration?.emptyStateView}
                    errorStateView={callLogsConfiguration?.errorStateView}
                    loadingIconURL={callLogsConfiguration?.loadingIconURL}
                    loadingStateView={callLogsConfiguration?.loadingStateView}
                    callLogRequestBuilder={callLogsConfiguration?.callLogRequestBuilder}
                    onItemClick={callLogsConfiguration?.onItemClick || undefined}
                    onError={callLogsConfiguration?.onError || undefined}
                    listItemStyle={callLogsConfiguration?.listItemStyle}
                    infoIconUrl={callLogsConfiguration?.infoIconUrl}
                    datePattern={callLogsConfiguration?.datePattern}
                    dateSeparatorPattern={callLogsConfiguration?.dateSeparatorPattern}
                    incomingAudioCallIconUrl={callLogsConfiguration?.incomingAudioCallIconUrl}
                    incomingVideoCallIconUrl={callLogsConfiguration?.incomingVideoCallIconUrl}
                    outgoingAudioCallIconUrl={callLogsConfiguration?.outgoingAudioCallIconUrl}
                    outgoingVideoCallIconUrl={callLogsConfiguration?.outgoingVideoCallIconUrl}
                    missedAudioCallIconUrl={callLogsConfiguration?.missedAudioCallIconUrl}
                    missedVideoCallIconUrl={callLogsConfiguration?.missedVideoCallIconUrl}
                    callLogsStyle={callLogsConfiguration?.callLogsStyle}
                    avatarStyle={callLogsConfiguration?.avatarStyle}
                    hideSeparator={callLogsConfiguration?.hideSeparator}
                    outgoingCallConfiguration={callLogsConfiguration?.outgoingCallConfiguration}
                />
            </div>
            <div style={getCallDetailStyle()}>
                {
                    activeCall ?
                        <CometChatCallLogDetails
                            title={localize("CALL_DETAIL")}
                            call={activeCall}
                            onBackClick={() => { return callLogDetailsConfiguration?.onBackClick || getBackClick }}
                            backIconUrl={callLogDetailsConfiguration?.backIconUrl}
                            avatarStyle={callLogDetailsConfiguration?.avatarStyle}
                            data={callLogDetailsConfiguration?.data || undefined}
                            callLogHistoryConfiguration={callLogDetailsConfiguration?.callLogHistoryConfiguration}
                            callLogParticipantsConfiguration={callLogDetailsConfiguration?.callLogParticipantsConfiguration}
                            callLogRecordingsConfiguration={callLogDetailsConfiguration?.callLogRecordingsConfiguration}
                            callLogDetailsStyle={callLogDetailsConfiguration?.callLogDetailsStyle}
                        /> :
                        <div style={getEmptyContainerStyle()}>
                            <cometchat-label
                                text={messageText}
                                labelStyle={JSON.stringify(getLabelStyle(theme, withDetailsStyle!))}
                            ></cometchat-label>
                        </div>
                }
            </div>
        </div>
    )
}


export { CometChatCallLogsWithDetails };
