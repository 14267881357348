import { ListItemStyle } from "@cometchat/uikit-elements";
import { DatePatterns, States, localize } from "@cometchat/uikit-resources";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { useCallback, useContext, useMemo } from "react";
import { convertSecondsToHoursMinutesSeconds, downloadRecordingFromURL } from "../Utils/utils";
import { CallLogRecordingsStyle } from "@cometchat/uikit-shared";
import { getBackButtonStyle, getButtonContainerStyle, getCallDateStyle, getContainerStyle, getDownloadButtonStyle, getListItemStyle, getListStyle, getSubtitleStyle, getTitleStyle } from "./style";
import { CometChatList } from "../../Shared/Views/CometChatList";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import backIcon from "./assets/backbutton.svg";
import downloadIcon from "./assets/download.svg";
import { CometChatButton } from "../../Shared/Views/CometChatButton";

interface ICallLogRecordingsProps {
    title?: string;
    backIconUrl?: string;
    downloadIconUrl?: string;
    hideDownloadButton?: boolean;
    call: any;
    datePattern?: DatePatterns;
    listItemStyle?: ListItemStyle;
    callLogRecordingsStyle?: CallLogRecordingsStyle;
    onBackClick?: Function;
    onItemClick?: Function;
    onDownloadClick?: Function;
    listItemView?: any;
    subtitleView?: any;
    tailView?: any;
}

const defaultProps: ICallLogRecordingsProps = {
    title: localize("RECORDING"),
    backIconUrl: backIcon,
    downloadIconUrl: downloadIcon,
    hideDownloadButton: false,
    call: undefined,
    datePattern: DatePatterns.time,
    listItemStyle: {} as ListItemStyle,
    callLogRecordingsStyle: {} as CallLogRecordingsStyle,
    onBackClick: undefined,
    onItemClick: undefined,
    onDownloadClick: undefined,
    listItemView: null,
    subtitleView: null,
    tailView: null
}

const CometChatCallLogRecordings = (props: ICallLogRecordingsProps) => {
    const {
        title,
        backIconUrl,
        downloadIconUrl,
        hideDownloadButton,
        call,
        datePattern,
        listItemStyle,
        callLogRecordingsStyle,
        onBackClick,
        onItemClick,
        onDownloadClick,
        listItemView,
        subtitleView,
        tailView
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);

    const handleItemClick = useCallback((item: any) => {
        if (onItemClick) {
            onItemClick(item);
        }
    }, [onItemClick])

    const handleDownloadClick = useCallback((item: any) => {
        if (onDownloadClick) {
            onDownloadClick(item);
        } else {
            downloadRecordingFromURL(item?.getRecordingURL())
        }
    }, [onDownloadClick])

    const getRecordings = useCallback(() => {
        try {
            return call?.getRecordings();
        } catch (e) {
            console.log(e);
        }
    }, [call])

    const getRecordingStartTime = (item: any) => {
        try {
            return item?.getStartTime();
        } catch (e) {
            console.log(e);
        }
    }

    const getRecordingDuration = (item: any) => {
        try {
            return convertSecondsToHoursMinutesSeconds(item?.getDuration());
        } catch (e) {
            console.log(e);
        }
    }

    const getListItemSubtitleView = useCallback((item: any): JSX.Element => {
        if (subtitleView) {
            return subtitleView(item);
        } else {
            return (
                <>
                    <div style={getSubtitleStyle(theme, callLogRecordingsStyle!)}>
                        {getRecordingDuration(item)}
                    </div>
                </>
            );
        }
    }, [subtitleView, theme, callLogRecordingsStyle])

    const getListItemTailView = useCallback((item: any): JSX.Element => {
        if (tailView) {
            return tailView(item);
        } else {
            return (
                <>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <cometchat-date
                            dateStyle={JSON.stringify(getCallDateStyle(theme, callLogRecordingsStyle!))}
                            pattern={datePattern}
                            timestamp={getRecordingStartTime(item)}
                        />
                        {
                            !hideDownloadButton ?
                                <CometChatButton
                                    iconURL={downloadIconUrl}
                                    buttonStyle={getDownloadButtonStyle(theme, callLogRecordingsStyle!)}
                                    onClick={() => handleDownloadClick(item)}
                                /> :
                                null
                        }
                    </div>
                </>
            );
        }
    }, [tailView, theme, callLogRecordingsStyle, datePattern, hideDownloadButton, downloadIconUrl, handleDownloadClick])

    const getListItem = useMemo(() => {
        return function (item: any, index: number): any {
            if (listItemView) {
                return listItemView(item);
            } else {
                return (
                    <>
                        <CometChatListItem
                            title={item?.getRid()}
                            listItemStyle={getListItemStyle(theme, listItemStyle)}
                            hideSeparator={true}
                            onClick={e => handleItemClick?.(item)}
                            subtitleView={getListItemSubtitleView(item)}
                            tailView={getListItemTailView(item)}
                        />
                    </>
                )
            }
        }
    }, [listItemView, theme, listItemStyle, getListItemSubtitleView, getListItemTailView, handleItemClick])

    return (
        <>
            <div style={getContainerStyle(theme, callLogRecordingsStyle!)}>
                <div style={{ display: "flex", padding: "10px 20px", alignItems: "center", marginBottom: "10px" }}>
                    {onBackClick ?
                        <div style={getButtonContainerStyle()}>
                            <CometChatButton iconURL={backIconUrl} buttonStyle={getBackButtonStyle(theme, callLogRecordingsStyle!)} onClick={onBackClick?.()} />
                        </div> :
                        null
                    }
                    <div style={getTitleStyle(theme, callLogRecordingsStyle!)}>
                        {title}
                    </div>
                </div>
                <CometChatList
                    hideSearch={true}
                    list={getRecordings() || []}
                    listItem={getListItem}
                    listItemKey="getRid"
                    listStyle={getListStyle(theme, callLogRecordingsStyle!)}
                    state={States.loaded}
                    showSectionHeader={false}
                />
            </div>
        </>
    )
}

export { CometChatCallLogRecordings };