import { CallLogDetailsStyle, CallLogHistoryConfiguration, CallLogParticipantsConfiguration, CallLogRecordingsConfiguration, } from "@cometchat/uikit-shared";
import { CometChatCallDetailsOption, CometChatDetailsTemplate, CometChatTheme, fontHelper, localize, } from "@cometchat/uikit-resources";
import { getBackButtonStyle, getButtonContainerStyle, getContainerStyle, getListItemStyle, getProfileContainerStyle, getTitleStyle } from "./style";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from "react";

import { AvatarStyle } from "@cometchat/uikit-elements";
import { CallingDetailsUtils } from "../Utils/CallDetailsUtils";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatButton } from "../../Shared/Views/CometChatButton";
import { CometChatCallLogHistory } from "../CometChatCallLogHistory";
import { CometChatCallLogParticipants } from "../CometChatCallLogParticipants";
import { CometChatCallLogRecordings } from "../CometChatCallLogRecordings";
import { CometChatListItem } from "../../Shared/Views/CometChatListItem";
import { CometChatThemeContext } from "../../CometChatThemeContext";
import { Hooks } from "./hooks";
import backIcon from "./assets/backbutton.svg";
import { verifyCallUser } from "../Utils/utils";

interface ICallLogDetailsProps {
    title?: string;
    backIconUrl?: string;
    call: CometChat.Call;
    onBackClick?: Function;
    avatarStyle?: AvatarStyle;
    data?: (callLog: any, loggedInUser: CometChat.User, theme: CometChatTheme) => CometChatDetailsTemplate[];
    callLogHistoryConfiguration?: CallLogHistoryConfiguration;
    callLogParticipantsConfiguration?: CallLogParticipantsConfiguration;
    callLogRecordingsConfiguration?: CallLogRecordingsConfiguration;
    callLogDetailsStyle?: CallLogDetailsStyle;
}

const defaultProps = {
    title: localize("CALL_DETAIL"),
    backIconUrl: backIcon,
    onBackClick: undefined,
    avatarStyle: {} as AvatarStyle,
    data: (callLog: any, loggedInUser: CometChat.User, theme: CometChatTheme) => [] as CometChatDetailsTemplate[],
    callLogHistoryConfiguration: {} as CallLogHistoryConfiguration,
    callLogParticipantsConfiguration: {} as CallLogParticipantsConfiguration,
    callLogRecordingsConfiguration: {} as CallLogRecordingsConfiguration,
    callLogDetailsStyle: {} as CallLogDetailsStyle,
};

const CometChatCallLogDetails = (props: ICallLogDetailsProps) => {
    const {
        title,
        backIconUrl,
        call,
        onBackClick,
        avatarStyle,
        data,
        callLogHistoryConfiguration,
        callLogParticipantsConfiguration,
        callLogRecordingsConfiguration,
        callLogDetailsStyle
    } = { ...defaultProps, ...props };

    const { theme } = useContext(CometChatThemeContext);
    const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null>(null);
    const [detailsTemplates, setDetailsTemplates] = useState<CometChatDetailsTemplate[]>([]);
    const [showParticipants, setShowParticipants] = useState(false);
    const [showRecordings, setShowRecordings] = useState(false);
    const [showCallHistory, setShowCallHistory] = useState(false);


    const getAvatarStyle = useCallback(() => {
        return { ...new AvatarStyle(defaultAvatarStyle(theme)), ...avatarStyle };
    }, [avatarStyle, theme]);


    const defaultAvatarStyle = (theme: CometChatTheme) => {
        return {
            borderRadius: "24px",
            width: "50px",
            height: "50px",
            border: "none",
            backgroundColor: theme.palette.getAccent700(),
            nameTextColor: theme.palette.getAccent900(),
            backgroundSize: "cover",
            nameTextFont: fontHelper(theme.typography.subtitle1),
            outerViewBorder: "",
            outerViewBorderSpacing: ""
        };
    }

    const getAvatarTitleStyle = (theme: CometChatTheme, style: CallLogDetailsStyle) => {
        return {
            color: style?.nameTextColor || theme.palette.getAccent(),
            font: style?.nameTextFont || fontHelper(theme.typography.title1),
        };
    }

    const setTemplates = useCallback(() => {
        let templatesArray = data?.(call!, loggedInUser!, theme);
        if (!templatesArray || !templatesArray.length) {
            templatesArray = CallingDetailsUtils.getDefaultCallTemplate(call, loggedInUser!, theme);
        }

        setDetailsTemplates([...templatesArray]);
    }, [setDetailsTemplates, call, loggedInUser, theme]);

    const getOptionsForTemplate = useCallback((template: CometChatDetailsTemplate): CometChatCallDetailsOption[] => {
        const { options, id } = template;
        let curOptions: CometChatCallDetailsOption[] = [];
        if (options && id !== undefined) {
            const callEntity = verifyCallUser(call, loggedInUser!);
            if (callEntity?.getUid) {
                curOptions = options(callEntity, null, id);
            } else {
                curOptions = options(null, callEntity, id);
            }
        }
        return curOptions;
    }, [call, loggedInUser])

    const handleOptionClick = useCallback((option: CometChatCallDetailsOption) => {
        if (option?.onClick) {
            option.onClick(call);
        } else {
            switch (option.id) {
                case "participants":
                    setShowParticipants(true);
                    break;
                case "recordings":
                    setShowRecordings(true);
                    break;
                case "callHistory":
                    setShowCallHistory(true);
                    break;
                default:
                    break;
            }
        }
    }, [call, setShowParticipants, setShowRecordings, setShowCallHistory])

    const showDetailsPage = useCallback(() => {
        return (
            loggedInUser && (
                !showParticipants &&
                !showRecordings &&
                !showCallHistory)
        );
    }, [loggedInUser, showParticipants, showRecordings, showCallHistory])

    const showParticipantsPage = useCallback(() => {
        return (
            loggedInUser &&
            showParticipants &&
            !showRecordings &&
            !showCallHistory
        );
    }, [loggedInUser, showParticipants, showRecordings, showCallHistory])

    const showRecordingsPage = useCallback(() => {
        return (
            loggedInUser &&
            !showParticipants &&
            showRecordings &&
            !showCallHistory
        );
    }, [loggedInUser, showParticipants, showRecordings, showCallHistory])

    const showCallHistoryPage = useCallback(() => {
        return (
            loggedInUser &&
            !showParticipants &&
            !showRecordings &&
            showCallHistory
        );
    }, [loggedInUser, showParticipants, showRecordings, showCallHistory])

    const handlePageOnBackClick = useCallback(() => {
        setShowParticipants(false);
        setShowRecordings(false);
        setShowCallHistory(false);
    }, [setShowParticipants, setShowRecordings, setShowCallHistory])

    const getCallUser = useCallback(() => {
        const entity = verifyCallUser(call, loggedInUser!);
        if (entity?.getUid) {
            return entity;
        } else {
            return undefined;
        }
    }, [call, loggedInUser])

    const getCallGroup = useCallback(() => {
        const entity = verifyCallUser(call, loggedInUser!);
        if (entity?.getGuid) {
            return entity;
        } else {
            return undefined;
        }
    }, [call, loggedInUser])

    const getTemplatesUI = useCallback(() => {
        return (
            <>
                {
                    detailsTemplates.map((template) => {
                        return (
                            <div>
                                {getOptionsForTemplate(template).map((option) => {

                                    const listItemStyle = {
                                        backgroundColor: option.backgroundColor,
                                        titleColor: option.titleColor,
                                        titleFont: option.titleFont
                                    }

                                    return (
                                        <div>
                                            <div key={option?.id} style={{ width: "100%" }} className="cc-call-details__options">
                                                {
                                                    option.customView ?
                                                        <div className="cc-call-details__option-content" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} onClick={() => handleOptionClick(option)}>
                                                            {option.customView}
                                                        </div> :
                                                        <CometChatListItem
                                                            title={option.title}
                                                            avatarURL={option.iconURL}
                                                            onClick={() => handleOptionClick(option)}
                                                            listItemStyle={getListItemStyle(theme, listItemStyle)}
                                                            hideSeparator={template.hideItemSeparator}
                                                            avatarStyle={new AvatarStyle({
                                                                backgroundColor: option.iconTint,
                                                            })}
                                                            id={option.id}
                                                            key={option.id}
                                                            tailView={option.tail}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                {template.title}
                            </div>
                        )
                    })
                }
            </>
        )
    }, [detailsTemplates, getOptionsForTemplate, handleOptionClick])

    Hooks(loggedInUser, setLoggedInUser, call, setTemplates);

    return (
        <>
            <div style={getContainerStyle(theme, callLogDetailsStyle!)}>
                {
                    showDetailsPage() ?
                        <div>
                            {
                                onBackClick ?
                                    <div style={getButtonContainerStyle()}>
                                        <CometChatButton iconURL={backIconUrl} buttonStyle={getBackButtonStyle(theme, callLogDetailsStyle!)} onClick={onBackClick?.()} />
                                        <div style={getTitleStyle(theme, callLogDetailsStyle!)}>
                                            {title}
                                        </div>
                                    </div> :
                                    null
                            }
                            {/* </div> */}
                            <div style={getProfileContainerStyle()}>
                                <cometchat-avatar avatarStyle={JSON.stringify(getAvatarStyle())} image={verifyCallUser(call, loggedInUser!).avatar} name={verifyCallUser(call, loggedInUser!).name}></cometchat-avatar>
                                <span style={getAvatarTitleStyle(theme, callLogDetailsStyle!)}>{verifyCallUser(call, loggedInUser!).name}</span>
                            </div>
                            {getTemplatesUI()}
                        </div> :
                        null
                }
                {
                    showParticipantsPage() ?
                        <CometChatCallLogParticipants
                            call={call}
                            title={localize("PARTICIPANTS")}
                            backIconUrl={callLogParticipantsConfiguration?.backIconUrl || backIconUrl}
                            avatarStyle={callLogParticipantsConfiguration?.avatarStyle || avatarStyle}
                            onBackClick={() => { return callLogParticipantsConfiguration?.onBackClick || handlePageOnBackClick }}
                            onItemClick={callLogParticipantsConfiguration?.onItemClick || undefined}
                            datePattern={callLogParticipantsConfiguration?.datePattern || undefined}
                            listItemStyle={callLogParticipantsConfiguration?.listItemStyle || undefined}
                            callLogParticipantsStyle={callLogParticipantsConfiguration?.callLogParticipantsStyle || undefined}
                            listItemView={callLogParticipantsConfiguration?.listItemView || null}
                            subtitleView={callLogParticipantsConfiguration?.subtitleView || null}
                            tailView={callLogParticipantsConfiguration?.tailView || null}
                        /> :
                        null
                }

                {
                    showRecordingsPage() ?
                        <CometChatCallLogRecordings
                            call={call}
                            title={localize("RECORDING")}
                            backIconUrl={callLogRecordingsConfiguration?.backIconUrl || backIconUrl}
                            onBackClick={() => { return callLogRecordingsConfiguration?.onBackClick || handlePageOnBackClick }}
                            onItemClick={callLogRecordingsConfiguration?.onItemClick || undefined}
                            datePattern={callLogRecordingsConfiguration?.datePattern || undefined}
                            listItemStyle={callLogRecordingsConfiguration?.listItemStyle || undefined}
                            callLogRecordingsStyle={callLogRecordingsConfiguration?.callLogRecordingsStyle || undefined}
                            downloadIconUrl={callLogRecordingsConfiguration?.downloadIconUrl || undefined}
                            hideDownloadButton={callLogRecordingsConfiguration?.hideDownloadButton || undefined}
                            onDownloadClick={callLogRecordingsConfiguration?.onDownloadClick || undefined}
                            listItemView={callLogRecordingsConfiguration?.listItemView || null}
                            subtitleView={callLogRecordingsConfiguration?.subtitleView || null}
                            tailView={callLogRecordingsConfiguration?.tailView || null}
                        /> :
                        null
                }

                {
                    showCallHistoryPage() ?
                        <CometChatCallLogHistory
                            callUser={getCallUser()}
                            callGroup={getCallGroup()}
                            title={localize("CALL_HISTORY")}
                            backIconUrl={callLogHistoryConfiguration?.backIconUrl || backIconUrl}
                            listItemStyle={callLogHistoryConfiguration?.listItemStyle || undefined}
                            emptyStateView={callLogHistoryConfiguration?.emptyStateView || null}
                            errorStateView={callLogHistoryConfiguration?.errorStateView || null}
                            loadingIconURL={callLogHistoryConfiguration?.loadingIconURL || undefined}
                            loadingStateView={callLogHistoryConfiguration?.loadingStateView || null}
                            subtitleView={callLogHistoryConfiguration?.subtitleView || undefined}
                            tailView={callLogHistoryConfiguration?.tailView || null}
                            callLogRequestBuilder={callLogHistoryConfiguration?.callLogRequestBuilder || null}
                            onItemClick={callLogHistoryConfiguration?.onItemClick || undefined}
                            onBackClick={() => { return callLogHistoryConfiguration?.onBackClick || handlePageOnBackClick }}
                            datePattern={callLogHistoryConfiguration?.datePattern || undefined}
                            dateSeparatorPattern={callLogHistoryConfiguration?.dateSeparatorPattern || undefined}
                            callLogHistoryStyle={callLogHistoryConfiguration?.callLogHistoryStyle || undefined}
                            onError={callLogHistoryConfiguration?.onError || undefined}
                        /> :
                        null
                }
            </div>
        </>
    )

}

export { CometChatCallLogDetails };
